import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery, Theme, Grid, Typography, Stack, Button, Divider } from '@mui/material';
import { useGetOne } from 'react-admin';

// import components
import LineChart from './components/LineChart';
import Type from './components/Type';
import Filter from './components/Filter';
import Price from '../../components/other/Price';
import SaveDashboard from './components/SaveDashboard';

const Reports = () => {

    const [type, setType] = React.useState("refuelings");
    const [filter, setFilter] = React.useState("last_month");

    const { data, isLoading, error } = useGetOne(`analytics/bytime/${type}/${filter}`, { id: "" });
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const onChangeType = (type: string) => {
        setType(type);
    }

    const onChangeFilter = (filter: string) => {
        setFilter(filter);
    }

    return (
        <Box sx={{ pt: isSmall ? 3 : 7 }}>
            <Box sx={{ mb: 2 }}>
                <Stack direction="row" spacing={1}>
                    <Box>
                        <Type changeType={onChangeType} />
                    </Box>
                    <Box>
                        <Filter changeFilter={onChangeFilter} />
                    </Box>
                    {!isSmall &&
                        <Box>
                            <SaveDashboard filter_type={filter} />
                        </Box>
                    }
                </Stack>
            </Box>
            <Divider />
            <Box sx={{ ml: 1, mt: 2, mb: 2 }}>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={3.7}>
                        <Box sx={{ textAlign: "center" }}>
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <Price price={data?.by_total ? data?.by_total : 0} colors="#fff" base_currency="ALL" size={isSmall ? 14 : 28} />
                                <Typography fontSize={isSmall ? 14 : 28} fontWeight="bold" sx={{ ml: 1 }}>ALL</Typography>
                            </Box>
                            <Typography fontSize={isSmall ? 12 : 16} color="gray">By Total</Typography>
                        </Box>
                    </Grid>
                    <Divider orientation="vertical" flexItem sx={{ borderColor: "gray", borderWidth: "0px 1px", height: "35px", mt: isSmall ? 2 : 4 }} />
                    <Grid item md={3} xs={3.7}>
                        <Box sx={{ textAlign: "center" }}>
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <Price price={data?.by_day ? data?.by_day : 0} colors="#fff" base_currency="ALL" size={isSmall ? 14 : 28} />
                                <Typography fontSize={isSmall ? 14 : 28} fontWeight="bold" sx={{ ml: 1 }}>ALL</Typography>
                            </Box>
                            <Typography fontSize={isSmall ? 12 : 16} color="gray">By Days</Typography>
                        </Box>
                    </Grid>
                    <Divider orientation="vertical" flexItem sx={{ borderColor: "gray", borderWidth: "0px 1px", height: "35px", mt: isSmall ? 2 : 4 }} />
                    <Grid item md={3} xs={3.7}>
                        <Box sx={{ textAlign: "center" }}>
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <Price price={data?.by_km ? data?.by_km : 0} colors="#fff" base_currency="ALL" size={isSmall ? 14 : 28} />
                                <Typography fontSize={isSmall ? 14 : 28} fontWeight="bold" sx={{ ml: 1 }}>ALL</Typography>
                            </Box>
                            <Typography fontSize={isSmall ? 12 : 16} color="gray">By Km</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <Box sx={{ mb: 2, mt: 1 }}>
                <LineChart type={type} />
            </Box>
        </Box>
    );

};

export default Reports;