import React, { FC } from 'react';
import { useNotify, useRefresh, useUpdate } from 'react-admin';
import { Box, Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { lightBlue } from '@mui/material/colors';
import Chip from '@mui/material/Chip';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';
import LockIcon from '@mui/icons-material/Lock';

const ChangePassword: FC = () => {

    const notify = useNotify();

    const [state, setState] = React.useState(false);

    const [update, { isLoading, error, data }] = useUpdate();

    const toggleDrawerOpen = () => {
        setState(true);
    };

    const toggleDrawerClose = () => {
        setState(false);
    }

    const { register, watch, setValue, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = async (data: any) => {

        if (data.new_password == data.repeat_password) {

            update('user/change-password', {
                data: {
                    old_password: data.old_password,
                    new_password: data.new_password,
                    repeat_password: data.repeat_password
                },
                id: ""
            });

        } else {
            notify("Password is not the same with repeat password", { type: "info" });
        }

    }

    if (data) {
        notify("Your password updated successfully", { type: "success" });
    }

    if (error) {
        notify("Password was not updated successfully", { type: "error" });
    }

    return (
        <>
            <Box>
                <Avatar sx={{ bgcolor: "#ddd" }} variant="rounded">
                    <LockIcon />
                </Avatar>
            </Box>
            <Box>
                <Typography sx={{ fontSize: 18, color: "#ddd", mt: -0.5 }}>Change Password</Typography>
                <Typography sx={{ fontSize: 11, color: "gray", mb: 1 }}>Change your password account</Typography>
                <Button variant="text" color="warning" startIcon={<LockIcon />} onClick={toggleDrawerOpen}>
                    Change
                </Button>
            </Box>
            <Drawer
                anchor="right"
                open={state}
                onClose={toggleDrawerClose}
                PaperProps={{
                    style: {
                        borderRadius: 0,
                        backgroundColor: "#000"
                    }
                }}
            >
                <Box sx={{ p: 5, width: 350 }}>
                    <LockIcon sx={{ color: "white", fontSize: 45 }} />
                    <Typography sx={{ color: "white", fontSize: 20, mt: 1 }}>Change Password</Typography>
                    <Box sx={{ mt: 1, mb: 1 }}></Box>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                        <Box sx={{ m: 5 }}>
                            <Divider />
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item md={12}>
                                <TextField
                                    autoComplete="off"
                                    id="old_password"
                                    type="password"
                                    label="Old Password *"
                                    placeholder="Old Password"
                                    {...register("old_password")}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    autoComplete="off"
                                    id="new_password"
                                    type="password"
                                    label="New Password *"
                                    placeholder="New Password"
                                    {...register("new_password")}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ m: 2 }}></Box>
                        <Grid container spacing={3}>
                            <Grid item md={12}>
                                <TextField
                                    autoComplete="off"
                                    id="repeat_password"
                                    type="password"
                                    label="Repeat Password *"
                                    placeholder="Repeat Password"
                                    {...register("repeat_password")}
                                    disabled={true}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ m: 5 }}></Box>
                        <Grid container spacing={2}>
                            <Grid item md={5}>
                                <Button
                                    variant="outlined"
                                    type="button"
                                    onClick={() => setState(false)}
                                    fullWidth
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item md={5}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    size="large"
                                    fullWidth
                                >
                                    Change
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Drawer>
        </>
    );

};

export default ChangePassword;