import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery, Theme, Grid, Typography } from '@mui/material';
import { useLogout, usePermissions } from 'react-admin';
import { useGetOne } from 'react-admin';

const OverviewTab = ({ markerData, gpsdata }: any) => {

    const { data, isLoading, error, refetch } = useGetOne('gps-track/vehicle-status', { id: gpsdata.id });

    const variables = data?.variables.reduce((acc: any, variable: any) => {
        acc[variable.name] = variable.value;
        return acc;
    }, {});

    // React.useEffect(() => {
    //     setInterval(() => {
    //         refetch();
    //     }, 10000)
    // }, [])

    return (
        <Box sx={{ p: 3 }}>
            {variables &&
                <>
                    {variables["_Odometer"] && variables["Ignition"] &&
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Box sx={{
                                backgroundColor: "#333",
                                flex: 1,
                                textAlign: "center",
                                ml: 1,
                                borderRadius: 2,
                                height: 50,
                                pt: 1
                            }}>
                                <Typography fontSize={12} color="gray">Odometer</Typography>
                                <Typography>
                                    {Math.round(variables["_Odometer"])} km
                                </Typography>
                            </Box>
                            <Box sx={{
                                backgroundColor: "#333",
                                flex: 1,
                                textAlign: "center",
                                ml: 1,
                                borderRadius: 2,
                                height: 50,
                                pt: 1
                            }}>
                                <Typography fontSize={12} color="gray">Ignition</Typography>
                                <Typography>
                                    {variables["Ignition"] == "True" ? "ON" : "OFF"}
                                </Typography>
                            </Box>
                        </Box>
                    }
                    {variables["BatteryVoltage"] && variables["Voltage"] &&
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 2 }}>
                            <Box sx={{
                                backgroundColor: "#333",
                                flex: 1,
                                textAlign: "center",
                                ml: 1,
                                borderRadius: 2,
                                height: 50,
                                pt: 1
                            }}>
                                <Typography fontSize={12} color="gray">Battery Voltage</Typography>
                                <Typography>
                                    {variables["BatteryVoltage"]} V
                                </Typography>
                            </Box>
                            <Box sx={{
                                backgroundColor: "#333",
                                flex: 1,
                                textAlign: "center",
                                ml: 1,
                                borderRadius: 2,
                                height: 50,
                                pt: 1
                            }}>
                                <Typography fontSize={12} color="gray">Voltage</Typography>
                                <Typography>
                                    {variables["Voltage"]} V
                                </Typography>
                            </Box>
                        </Box>
                    }
                </>
            }
        </Box>
    );

};

export default OverviewTab;