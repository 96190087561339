import React, { FC, useState, useEffect, useCallback } from 'react';
import { Box, Accordion, AccordionSummary, useMediaQuery, Theme, Typography, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Help: FC = () => {

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    return (
        <Box sx={{ pt: isSmall ? 3 : 7 }}>
            <Typography sx={{ width: '33%', fontSize: 22, color: "#ddd", mb: 1.5, flexShrink: 0 }}>
                Get Help
            </Typography>
            <Accordion sx={{ p: 2, backgroundColor: "#111" }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: "bold" }}>
                        What is auttom.io and what does SaaS Solution mean?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Consider auttom.io as your plug-&-play fleet management solution delivered on the cloud.
                        auttom.io provides a robust & flexible Vehicle/Fleet management  system to enable automated experience on your daily operations and provide powerful analytics. We provide the ultimate tools to automate, control your vehicle/fleet & react promptly to your company logistic needs. Auttom helps you take better decisions towards your vehicle/fleet and increase your profitability by maintaining expenses under control.
                        You can connect with auttom.io via API if you are technically inclined.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ p: 2, backgroundColor: "#111" }} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography sx={{ color: 'text.secondary', fontWeight: "bold" }}>
                        Why auttom? Why should I use it instead of building my own fleet management system?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Automated & analytical logistic operations are much more than managing your vehicles at periodic intervals. To ensure your company has centralized logistic operations, you need to provide a simple and easy to use system and We offer you just that.
                        Building an entire fleet system on your own requires a lot of time, effort and focus from management. Using us, you can least of all be at peace knowing that your entire logistic is handled by the experts in the most secure way.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ p: 2, backgroundColor: "#111" }} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: "bold" }}>
                        How soon can I start using your service?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        You can sign up online to explore our service & applications right away. To access analytical reports, you will need to create or import vehicles and records. It takes a few minutes to set up your account and start having real control over your logistic ops.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ p: 2, backgroundColor: "#111" }} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: "bold" }}>What happens if your service is down?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Auttom.io is hosted on data servers using Amazon & Google Web Services across multiple locations with full redundancy. We have taken several measures to ensure continued service across regions. Unless there is an outage across a large region & multiple providers/ data centers, the service will be accessible for users. We strive towards zero downtime, including deployments over weekends and our engineering team has extensive experience handling server scalability and you can rest assured of our ability to provide a highly available, secure and highly scalable service.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ p: 2, backgroundColor: "#111" }} expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5bh-content"
                    id="panel5bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: "bold" }}>Is it safe to access my data in auttom.io over the Internet?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Yes, absolutely. Everything you do in the auttom.io console and apps is on completely over HTTPS – your data is fully encrypted by an SSL connection and accessed over the internet.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ p: 2, backgroundColor: "#111" }} expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel6bh-content"
                    id="panel6bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: "bold" }}>Does auttom supports multiple fuel types or multiple tanks?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Yes we do support multiple fuel types like Diesel, CNG, Gasoline, LPG, Gas Premium and electric combined with more than one tank for each vehicle. If there is a fuel type you are looking for that is not available, drop us a mail and we will be sure to support it.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ p: 2, backgroundColor: "#111" }} expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel7bh-content"
                    id="panel7bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: "bold" }}>Is pricing/ licensing flexible?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Definitely YES. auttom.io pay as you go business model, allows you to upgrade/ downgrade  licensees based on your company & logistic needs.
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Box>
    )

}

export default Help;