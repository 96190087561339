import React, { useRef, FC, useCallback } from "react";
import { Box, Typography, Dialog, useMediaQuery, Theme, Grid, TextField, FormControl, Checkbox, CircularProgress, FormControlLabel, RadioGroup, Radio, Button } from '@mui/material';
import { useTranslate, useLogin, useNotify } from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useLocation } from 'react-router-dom';

// components
import SelectableFilter from '../components/inputs/SelectableFilter';

interface Props {
    continueRegistration: boolean;
    googleAccountCredentials: any;
}

const Signup: FC<Props> = ({ continueRegistration, googleAccountCredentials }) => {

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const notify = useNotify();
    const login = useLogin();
    const location = useLocation();

    const [loading, setLoading] = React.useState(false);

    const [open, setOpen] = React.useState(false);
    const [step, setStep] = React.useState(0);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onChangeAccountType = (e: any) => {
        setSettingsType(e.target.defaultValue);

        if (e.target.defaultValue == "company") {
            setShowComapnyName(true);
        } else {
            setShowComapnyName(false);
        }

    }

    const [email, setEmail] = React.useState<string>("");
    const [companyname, setCompanyName] = React.useState<string>("");
    const [firstname, setFirstName] = React.useState<string>("");
    const [lastname, setLastName] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");
    const [confirmpassword, setConfirmPassword] = React.useState<string>("");

    const [settingsType, setSettingsType] = React.useState("individual");
    const [showCompanyName, setShowComapnyName] = React.useState(false);

    const [selectedVehicleType, setSelectedVehicleType] = React.useState<any>("car");

    const onChangeVehicleType = (event: any) => {
        setSelectedVehicleType(event.target.defaultValue);
    }

    const [licensePlate, setLicensePlate] = React.useState<string>("");
    const [identificationVin, setIdentificationVin] = React.useState<string>("");

    const [selectedVehicleBrand, setSelectedVehicleBrand] = React.useState<any>({});

    const selectedVehicleBrandItem = (data: any) => {
        setSelectedVehicleBrand(data);
    }

    const [vehicleModel, setVehicleModel] = React.useState<string>("");

    const [vehicleYear, setVehicleYear] = React.useState<string>("");

    const [primaryFuelCapacity, setPrimaryFuelCapacity] = React.useState<any>(0);
    const [secondaryFuelCapacity, setSecondaryFuelCapacity] = React.useState<any>(0);

    const [twoTankChecked, setTwoTankChecked] = React.useState(false);

    const [primaryTank, setSelectedPrimaryTank] = React.useState<any>({});

    const selectedPrimaryTank = (data: any) => {
        setSelectedPrimaryTank(data);
    }

    const [secondaryTank, setSelectedSecondaryTank] = React.useState<any>({});

    const selectedSecondaryTank = (data: any) => {
        setSelectedSecondaryTank(data);
    }

    const [selectedKmType, setSelectedKmType] = React.useState<any>("km");

    const onChangeKmType = (event: any) => {
        setSelectedKmType(event.target.defaultValue);
    }

    const [fuels, setFuels] = React.useState<any>([]);

    React.useEffect(() => {

        if (!twoTankChecked) {
            setFuels([
                {
                    fuel_id: primaryTank.id,
                    unit_capacity: Number(primaryFuelCapacity),
                    type: "PRIMARY"
                }
            ]);
        } else {
            setFuels([
                {
                    fuel_id: primaryTank.id,
                    unit_capacity: Number(primaryFuelCapacity),
                    type: "PRIMARY"
                },
                {
                    fuel_id: secondaryTank.id,
                    unit_capacity: Number(secondaryFuelCapacity),
                    type: "SECONDARY"
                },
            ]);
        }

    }, [primaryFuelCapacity, secondaryFuelCapacity, primaryTank, secondaryTank]);

    const signupsaveddata: any = JSON.parse(`${localStorage.getItem("signupsaveddata")}`);

    React.useEffect(() => {

        if (signupsaveddata) {
            setEmail(signupsaveddata.accountdetails.email);
            setCompanyName(signupsaveddata.accountdetails.companyname);
            setFirstName(signupsaveddata.accountdetails.firstname);
            setLastName(signupsaveddata.accountdetails.lastname);
            setLicensePlate(signupsaveddata.vehicledetails.licensePlate);
            setIdentificationVin(signupsaveddata.vehicledetails.identificationVin);
            setVehicleModel(signupsaveddata.vehicledetails.vehicleModel);
            setVehicleYear(signupsaveddata.vehicledetails.vehicleYear);
            setPrimaryFuelCapacity(signupsaveddata.vehicledetails.primaryFuelCapacity);
            setSecondaryFuelCapacity(signupsaveddata.vehicledetails.secondaryFuelCapacity);
        }

    }, [step])

    const onSubmit = () => {

        let signup = {
            api: '/auth/user/signup',
            data: {
                account: {
                    settings_type: settingsType,
                    name: settingsType == "company" ? companyname : continueRegistration ? googleAccountCredentials.name : firstname + " " + lastname,
                    firstname: continueRegistration ? googleAccountCredentials.given_name : firstname,
                    lastname: continueRegistration ? googleAccountCredentials.family_name : lastname,
                    email: continueRegistration ? googleAccountCredentials.email : email,
                    password: continueRegistration ? googleAccountCredentials.sub : password,
                    google_token: "",
                    apple_token: ""
                },
                plan: {
                    id: 1
                },
                firstvehicle: {
                    data: {
                        type: selectedVehicleType,
                        make_id: selectedVehicleBrand.id,
                        make_model: vehicleModel,
                        license_plate: licensePlate,
                        identification_vin: identificationVin,
                        make_year: vehicleYear,
                        two_tanks: twoTankChecked,
                        km_type: selectedKmType
                    },
                    tanks: fuels
                }
            }
        }

        if (step === 0) {

            if (!continueRegistration) {
                if (email == "" || firstname == "" || lastname == "" || password == "" || confirmpassword == "") {
                    notify("All fields are required", {
                        type: "info"
                    })
                } else {

                    setStep(1);

                    localStorage.setItem("signupsaveddata", JSON.stringify({
                        accountdetails: {
                            email: email,
                            companyname: companyname,
                            firstname: firstname,
                            lastname: lastname
                        },
                        vehicledetails: {
                            licensePlate: licensePlate,
                            identificationVin: identificationVin,
                            vehicleYear: vehicleYear,
                            primaryFuelCapacity: primaryFuelCapacity,
                            secondaryFuelCapacity: secondaryFuelCapacity
                        }
                    }));

                }
            } else {
                setStep(1);
            }


        } else if (step === 1) {

            if (licensePlate == "" || identificationVin == "" || vehicleYear == "" || primaryFuelCapacity == 0) {
                notify("All fields are required", {
                    type: "info"
                });
            } else {

                setLoading(true);

                login(signup, location.state ? (location.state as any).nextPathname : '/').then((res) => {
                    localStorage.removeItem("signupsaveddata");
                }).catch(
                    (error: Error) => {
                        setLoading(false);
                        notify(
                            typeof error === 'string'
                                ? error
                                : typeof error === 'undefined' || !error.message
                                    ? 'ra.auth.sign_in_error'
                                    : error.message,
                            {
                                type: 'warning',
                                messageArgs: {
                                    _:
                                        typeof error === 'string'
                                            ? error
                                            : error && error.message
                                                ? error.message
                                                : undefined,
                                },
                            }
                        );
                    }
                );

            }

        }

    }

    React.useEffect(() => {
        setOpen(continueRegistration);
    }, [continueRegistration])

    return (
        <Box>
            <Typography onClick={handleClickOpen} sx={{ fontWeight: "bold", cursor: "pointer", color: "#ddd" }}>Sign Up Now</Typography>
            <Dialog
                maxWidth="lg"
                open={open}
                PaperProps={{
                    style: {
                        backgroundColor: '#000',
                        borderRadius: 30,
                        width: 800
                    }
                }}
            >
                <Box sx={{ p: isSmall ? 2 : 5 }}>
                    <Box sx={{ textAlign: "center" }}>
                        <Typography fontSize={isSmall ? 22 : 28} color="#ddd" fontWeight="bold">Welcome to auttom.io</Typography>
                        {step === 0 &&
                            <Typography fontWeight="bold" fontSize={18} color="#f04e55">Account Details</Typography>
                        }
                        {step === 1 &&
                            <Typography fontWeight="bold" fontSize={18} color="#f04e55">Vehicle Details</Typography>
                        }
                    </Box>
                    {step === 0 &&
                        <Box sx={{ m: isSmall ? 2 : 5 }}>
                            <Grid container spacing={0}>
                                <Grid item md={6} xs={12}>
                                    <FormControl sx={{ mt: 2 }}>
                                        <RadioGroup
                                            row
                                            aria-labelledby="settings_type"
                                            onChange={(e) => onChangeAccountType(e)}
                                            defaultValue="individual"
                                            name="Account Type"
                                        >
                                            <FormControlLabel value="individual" control={<Radio sx={{ color: "#ddd" }} />} label="Personal" sx={{ color: "#ddd" }} />
                                            <FormControlLabel value="company" control={<Radio sx={{ color: "#ddd" }} />} label="Company" sx={{ color: "#ddd" }} />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {showCompanyName && continueRegistration &&
                                <Grid container spacing={2} sx={{ mt: 0.5 }}>
                                    <Grid item xs={6}>
                                        <TextField
                                            autoComplete="off"
                                            id="name"
                                            label="Company Name"
                                            inputProps={{
                                                style: {
                                                    backgroundColor: "#222",
                                                    borderRadius: 10,
                                                    color: "#ddd"
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    color: "gray"
                                                }
                                            }}
                                            defaultValue={companyname}
                                            onChange={(e: any) => setCompanyName(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            }
                            {!continueRegistration &&
                                <>
                                    <Box sx={{ m: 2 }} />
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                autoComplete="off"
                                                id="email"
                                                label="Email"
                                                variant="outlined"
                                                inputProps={{
                                                    style: {
                                                        backgroundColor: "#222",
                                                        borderRadius: 10,
                                                        color: "#ddd"
                                                    }
                                                }}
                                                InputLabelProps={{
                                                    style: {
                                                        color: "gray"
                                                    }
                                                }}
                                                defaultValue={email}
                                                onChange={(e: any) => setEmail(e.target.value)}
                                                fullWidth
                                            />
                                        </Grid>
                                        {showCompanyName &&
                                            <Grid item xs={6}>
                                                <TextField
                                                    autoComplete="off"
                                                    id="name"
                                                    label="Company Name"
                                                    inputProps={{
                                                        style: {
                                                            backgroundColor: "#222",
                                                            borderRadius: 10,
                                                            color: "#222"
                                                        }
                                                    }}
                                                    InputLabelProps={{
                                                        style: {
                                                            color: "ddd"
                                                        }
                                                    }}
                                                    defaultValue={companyname}
                                                    onChange={(e: any) => setCompanyName(e.target.value)}
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                    <Box sx={{ m: 2 }} />
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                autoComplete="off"
                                                id="firstname"
                                                label="First Name"
                                                inputProps={{
                                                    style: {
                                                        backgroundColor: "#222",
                                                        borderRadius: 10,
                                                        color: "#ddd"
                                                    }
                                                }}
                                                InputLabelProps={{
                                                    style: {
                                                        color: "gray"
                                                    }
                                                }}
                                                defaultValue={firstname}
                                                onChange={(e: any) => setFirstName(e.target.value)}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                autoComplete="off"
                                                id="lastname"
                                                label="Last Name"
                                                inputProps={{
                                                    style: {
                                                        backgroundColor: "#222",
                                                        borderRadius: 10,
                                                        color: "#ddd"
                                                    }
                                                }}
                                                InputLabelProps={{
                                                    style: {
                                                        color: "gray"
                                                    }
                                                }}
                                                defaultValue={lastname}
                                                onChange={(e: any) => setLastName(e.target.value)}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box sx={{ m: 2 }} />
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                autoComplete="off"
                                                id="password"
                                                type="password"
                                                inputProps={{
                                                    style: {
                                                        backgroundColor: "#222",
                                                        borderRadius: 10,
                                                        color: "#ddd"
                                                    }
                                                }}
                                                InputLabelProps={{
                                                    style: {
                                                        color: "gray"
                                                    }
                                                }}
                                                label="Password"
                                                onChange={(e: any) => setPassword(e.target.value)}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                autoComplete="off"
                                                id="confirm_password"
                                                type="password"
                                                inputProps={{
                                                    style: {
                                                        backgroundColor: "#222",
                                                        borderRadius: 10,
                                                        color: "#ddd"
                                                    }
                                                }}
                                                InputLabelProps={{
                                                    style: {
                                                        color: "gray"
                                                    }
                                                }}
                                                label="Confirm Password"
                                                onChange={(e: any) => setConfirmPassword(e.target.value)}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </Box>
                    }
                    {step === 1 &&
                        <Box sx={{ m: isSmall ? 2 : 5 }}>
                            <FormControl>
                                <RadioGroup
                                    row
                                    onChange={(e) => onChangeVehicleType(e)}
                                    defaultValue="car"
                                    aria-labelledby="type"
                                    name="type"
                                >
                                    <FormControlLabel sx={{ color: "#ddd" }} value="car" control={<Radio sx={{ color: "#333" }} />} label="Car" />
                                    <FormControlLabel sx={{ color: "#ddd" }} value="truck" control={<Radio sx={{ color: "#333" }} />} label="Truck" />
                                    <FormControlLabel sx={{ color: "#ddd" }} value="bus" control={<Radio sx={{ color: "#333" }} />} label="Bus" />
                                    <FormControlLabel sx={{ color: "#ddd" }} value="motorcycle" control={<Radio sx={{ color: "#333" }} />} label="Motorcycle" />
                                </RadioGroup>
                            </FormControl>
                            <Box sx={{ m: 3 }}></Box>
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={6}>
                                    <TextField
                                        autoComplete="off"
                                        id="license_plate"
                                        label="License Plate *"
                                        inputProps={{
                                            style: {
                                                backgroundColor: "#222",
                                                borderRadius: 10,
                                                color: "#ddd"
                                            }
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: "gray"
                                            }
                                        }}
                                        defaultValue={licensePlate}
                                        onChange={(e: any) => setLicensePlate(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item md={6} xs={6}>
                                    <TextField
                                        autoComplete="off"
                                        id="identification_vin"
                                        label="Identification (VIN) *"
                                        inputProps={{
                                            style: {
                                                backgroundColor: "#222",
                                                borderRadius: 10,
                                                color: "#ddd"
                                            }
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: "gray"
                                            }
                                        }}
                                        defaultValue={identificationVin}
                                        onChange={(e: any) => setIdentificationVin(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{ m: 3 }}></Box>
                            <Typography sx={{ color: "gray", mb: 2, fontSize: isSmall ? 12 : 14 }}>Choose your vehicle brand and model ...</Typography>
                            <Grid container spacing={2}>
                                <Grid item md={3} xs={6}>
                                    <SelectableFilter resource="makes" title={isSmall ? "Brand" : "Vehicle Brand"} source="name" selected={selectedVehicleBrandItem} />
                                </Grid>
                                <Grid item md={4} xs={6}>
                                    <TextField
                                        autoComplete="off"
                                        id="make_model"
                                        inputProps={{
                                            style: {
                                                backgroundColor: "#222",
                                                borderRadius: 10,
                                                color: "#ddd"
                                            }
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: "gray"
                                            }
                                        }}
                                        label="Vehicle Model *"
                                        defaultValue={vehicleModel}
                                        onChange={(e: any) => setVehicleModel(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        autoComplete="off"
                                        type="number"
                                        id="make_year"
                                        inputProps={{
                                            style: {
                                                backgroundColor: "#222",
                                                borderRadius: 10,
                                                color: "#ddd"
                                            }
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: "gray"
                                            }
                                        }}
                                        label="Vehicle Year *"
                                        defaultValue={vehicleYear}
                                        onChange={(e: any) => setVehicleYear(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{ m: 3 }}></Box>
                            <Typography sx={{ color: "gray", fontSize: isSmall ? 12 : 14 }}>Choose vehicle tanks ...</Typography>
                            <Grid container spacing={2}>
                                <Grid item md={4} xs={6}>
                                    <SelectableFilter resource="fuel" title={isSmall ? "Primary" : "Primary Tank"} source="name" selected={selectedPrimaryTank} />
                                </Grid>
                                <Grid item md={3} xs={6}>
                                    <TextField
                                        autoComplete="off"
                                        type="number"
                                        id="primary_fuel_capacity"
                                        inputProps={{
                                            style: {
                                                backgroundColor: "#222",
                                                borderRadius: 10,
                                                color: "#ddd"
                                            }
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: "gray"
                                            }
                                        }}
                                        label={`${primaryTank && primaryTank.name == "Electric" ? "Fuel Capacity (kwh)" : "Fuel Capacity (L)"} *`}
                                        defaultValue={primaryFuelCapacity}
                                        onChange={(e: any) => setPrimaryFuelCapacity(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{ m: 3 }}></Box>
                            <Grid container spacing={2}>
                                <Grid item md={12} xs={20}>
                                    <Box>
                                        <Grid container spacing={2}>
                                            <Grid item md={6} xs={15}>
                                                <Checkbox
                                                    edge="start"
                                                    checked={twoTankChecked}
                                                    sx={{ display: "none" }}
                                                />
                                                <Button
                                                    startIcon={twoTankChecked ? <RemoveIcon /> : <AddIcon />}
                                                    onClick={() => { setTwoTankChecked((check: any) => !check) }}
                                                    sx={{
                                                        fontSize: 15
                                                    }}
                                                >
                                                    Secondary Tank
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        {twoTankChecked &&
                                            <>
                                                <Grid container spacing={2}>
                                                    <Grid item md={4} xs={6}>
                                                        <SelectableFilter resource="fuel" title={isSmall ? "Secondary" : "Secondary Tank"} source="name" selected={selectedSecondaryTank} />
                                                    </Grid>
                                                    <Grid item md={3} xs={6}>
                                                        <TextField
                                                            autoComplete="off"
                                                            type="number"
                                                            inputProps={{
                                                                style: {
                                                                    backgroundColor: "#222",
                                                                    borderRadius: 10,
                                                                    color: "#ddd"
                                                                }
                                                            }}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color: "gray"
                                                                }
                                                            }}
                                                            id="secondary_fuel_capacity"
                                                            label={`${secondaryTank && secondaryTank.name == "Electric" ? "Fuel Capacity (kwh)" : "Fuel Capacity (L)"} *`}
                                                            defaultValue={secondaryFuelCapacity}
                                                            onChange={(e: any) => setSecondaryFuelCapacity(e.target.value)}
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </>
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    }
                    <Box sx={{ m: 3 }} />
                    <Box sx={{ m: 5 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={0}>
                                <Button
                                    disabled={step <= 0}
                                    variant="contained"
                                    sx={{
                                        "&:disabled": {
                                            backgroundColor: "#222"
                                        },
                                        backgroundColor: "#f04e55"
                                    }}
                                    startIcon={<ChevronLeftIcon />}
                                    type="button"
                                    onClick={() => {
                                        localStorage.setItem("signupsaveddata", JSON.stringify({
                                            accountdetails: {
                                                email: email,
                                                companyname: companyname,
                                                firstname: firstname,
                                                lastname: lastname
                                            },
                                            vehicledetails: {
                                                licensePlate: licensePlate,
                                                identificationVin: identificationVin,
                                                vehicleYear: vehicleYear,
                                                primaryFuelCapacity: primaryFuelCapacity,
                                                secondaryFuelCapacity: secondaryFuelCapacity
                                            }
                                        }));
                                        setStep(0);
                                    }}
                                >
                                    Back
                                </Button>
                            </Grid>
                            <Grid item xs={0}>
                                {loading && step >= 1 ?
                                    <CircularProgress
                                        size={40}
                                        sx={{ ml: 2, mt: -0.2 }}
                                        thickness={5}
                                    />
                                    :
                                    <Button
                                        variant="contained"
                                        endIcon={<ChevronRightIcon />}
                                        sx={{ backgroundColor: "#f04e55" }}
                                        type="button"
                                        onClick={onSubmit}
                                    >
                                        {step >= 1 ? "Finish" : "Next"}
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ m: 5 }}>
                        <Grid container justifyContent="center" spacing={1}>
                            <Grid item xs={0}>
                                <Button variant="contained" sx={{ backgroundColor: "#f04e55" }} onClick={handleClose}>Cancel</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Dialog>
        </Box>
    )

}

export default Signup;