import React, { FC, useState, useEffect, useCallback } from 'react';
import { useNotify, useRefresh, useDataProvider, useGetOne, useUpdate } from 'react-admin';
import { useNavigate, useParams } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { useMediaQuery, Theme } from '@mui/material';
import SelectableFilter from '../../components/inputs/SelectableFilter';

const Edit: FC = () => {

    const navigate = useNavigate();
    const notify = useNotify();
    const refresh = useRefresh();

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const { data: settingsVehicleSelected, isLoading: isLoadingSettingsVehicleSelected, error: errorSettingsVehicleSelected } = useGetOne(
        'settings-vehicle-selected/selected',
        {
            id: ""
        }
    );

    const { id } = useParams();

    const [update, { isLoading, error, data }] = useUpdate();

    const [startDateValue, setStartDateValue] = React.useState<Dayjs | null>(
        dayjs(new Date())
    );

    const [endDateValue, setEndDateValue] = React.useState<Dayjs | null>(
        dayjs(new Date())
    );

    const [driver, setDriver] = React.useState<any>(0);

    const selectedDriver = (driver: any) => {
        setDriver(driver);
    }

    const { data: tasksDetails, isLoading: isLoadingTasksDetails, error: errorTasksDetails, refetch } = useGetOne(
        "tasks",
        {
            id: `${id}`
        }
    )

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const onSubmit = async (data: any) => {

        data.vehicle_id = settingsVehicleSelected?.vehicle.id;
        data.user_id = driver;
        data.start_date = startDateValue;
        data.end_date = endDateValue;

        if (driver !== 0) {
            update('tasks', { data: data, id: id });
        } else {
            notify("Please select driver to assign this task", { type: "info" });
        }

    };

    if (data) {
        notify("Your task updated successfully", { type: "success" });
        navigate("/tasks");
        refresh();
    }

    if (error) {
        notify("Your task was not updated successfully", { type: "error" });
    }

    useEffect(() => {

        if (tasksDetails) {
            setValue("name", tasksDetails.name);
            setValue("kilometers", tasksDetails.kilometers);
            setValue("reason", tasksDetails.reason);
            setValue("notes", tasksDetails.notes);
            setStartDateValue(tasksDetails.start_date);
            setEndDateValue(tasksDetails.end_date);
        }

    }, [tasksDetails]);

    return (
        <Box sx={{ pt: 6 }}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography sx={{ fontSize: 20, color: "#ddd" }}>Edit Task</Typography>
                </Grid>
            </Grid>
            <Box sx={{ m: 5 }} />
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={8}>
                        <TextField
                            autoComplete="off"
                            id="name"
                            label="Name *"
                            placeholder="Name"
                            InputLabelProps={{
                                shrink: true
                            }}
                            {...register("name", { required: true })}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Box sx={{ m: 4 }} />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container spacing={2}>
                        <Grid item md={3} xs={6}>
                            <MobileDatePicker
                                label="Start Date *"
                                inputFormat="DD/MM/YYYY"
                                value={startDateValue}
                                onChange={(newValue: any) => {
                                    setStartDateValue(newValue);
                                }}
                                renderInput={(params: any) => <TextField fullWidth {...params} />}
                            />
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <MobileDatePicker
                                label="End Date *"
                                inputFormat="DD/MM/YYYY"
                                value={endDateValue}
                                onChange={(newValue: any) => {
                                    setEndDateValue(newValue);
                                }}
                                renderInput={(params: any) => <TextField fullWidth {...params} />}
                            />
                        </Grid>
                    </Grid>
                </LocalizationProvider>
                <Box sx={{ m: 4 }} />
                <Grid container spacing={2}>
                    <Grid item md={3} xs={10}>
                        <TextField
                            autoComplete="off"
                            id="kilometers"
                            type="number"
                            label="Kilometers *"
                            placeholder="Kilometers"
                            InputLabelProps={{
                                shrink: true
                            }}
                            {...register("kilometers", { required: true })}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Box sx={{ m: 3 }}></Box>
                <Grid container spacing={2}>
                    <Grid item md={4} xs={10}>
                        <SelectableFilter resource="drivers" title="Assign to driver" source="firstname" selected={selectedDriver} />
                    </Grid>
                </Grid>
                <Box sx={{ m: 3 }}></Box>
                <Grid container spacing={2}>
                    <Grid item md={5} xs={12}>
                        <TextField
                            autoComplete="off"
                            id="reason"
                            label="Reason"
                            placeholder="Reason (Optional)"
                            InputLabelProps={{
                                shrink: true
                            }}
                            {...register("reason", { required: false })}
                            variant="outlined"
                            multiline
                            rows={2}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Box sx={{ m: 4 }} />
                <Grid container spacing={2}>
                    <Grid item md={5} xs={12}>
                        <TextField
                            autoComplete="off"
                            id="notes"
                            label="Notes"
                            placeholder="Notes (Optional)"
                            InputLabelProps={{
                                shrink: true
                            }}
                            {...register("notes", { required: false })}
                            variant="outlined"
                            multiline
                            rows={4}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Box sx={{ m: 5 }} />
                <Grid container spacing={2}>
                    <Grid item md={1} xs={3}>
                        <Button
                            variant="text"
                            size="large"
                            onClick={() => navigate("/tasks")}
                            fullWidth
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item md={1} xs={4}>
                        <Button
                            variant="contained"
                            type="submit"
                            size="large"
                            fullWidth
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    )

}

export default Edit;