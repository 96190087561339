import React, { useRef, FC, useCallback } from "react";
import { Box, Theme, Typography } from '@mui/material';

interface Props {
    base_currency: any
    price: any
    colors?: any
    size?: any
}

const Price: FC<Props> = ({ base_currency, price, colors, size }) => {

    return (
        <Box>
            <>
                <Typography style={{ fontSize: size ? size : 14, color: colors ? colors : "gray", fontWeight: "bold" }}>{price?.toLocaleString('en-US', { minimumFractionDigits: 0 })}</Typography>
            </>
        </Box>
    )

};

export default Price;