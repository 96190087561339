import * as React from 'react';
import { Divider, Stack } from '@mui/material';
import { Box, Typography, Avatar, Button } from '@mui/material';
import { useCreatePath, useGetOne, useListContext } from 'react-admin';
import { Link } from 'react-router-dom';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { Grid, CardContent, Card, CardActions } from '@mui/material';
import { format, addHours } from 'date-fns';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import NumberFormat from '../../components/other/NumberFormat';
import DeleteButtonComponent from '../../components/buttons/Delete';

const LoadedGridList = () => {

    const { data } = useListContext();
    if (!data) return null;

    return (
        <>
            <Box sx={{ backgroundColor: "#1010105e", p: 0.2 }} />
            <Grid sx={{ p: 3 }} container spacing={2}>
                {data?.map((record: any) => (
                    <Grid item md={2} xs={6}>
                        <Card sx={{ backgroundColor: "#222", height: 200 }}>
                            <CardContent>
                                <Typography sx={{ fontSize: 12 }} color="text.secondary">
                                    {record.repeat_type == "one_time" &&
                                        "One Time"
                                    }
                                    {record.repeat_type == "repeat_every" &&
                                        `Started: ${format(new Date(record.start_date), 'dd, MMM yyyy')}`
                                    }
                                </Typography>
                                <Typography sx={{ fontWeight: "bold" }}>
                                    {record.name}
                                </Typography>
                                {record.date_checkbox &&
                                    <Box sx={{ mt: 0.5 }}>
                                        <Typography sx={{ fontSize: 10 }}>based on date</Typography>
                                        <Typography sx={{ fontWeight: "bold", fontSize: 18 }} color="text.secondary">
                                            {format(new Date(record.date), "dd, MMMM yyyy")}
                                        </Typography>
                                    </Box>
                                }
                                {record.time_checkbox &&
                                    <>
                                        <Typography sx={{ fontWeight: "bold", mt: 1, fontSize: 18 }} color="text.secondary">
                                            {record.repeat_period == "day" &&
                                                "Every DAY at:"
                                            }
                                            {record.repeat_period == "month" &&
                                                "Every MONTH at:"
                                            }
                                            {record.repeat_period == "year" &&
                                                "Every YEAR at:"
                                            }
                                        </Typography>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary">
                                            {format(new Date(record.start_date), "dd, MMMM yyyy")}
                                        </Typography>
                                    </>
                                }
                            </CardContent>
                            <CardActions sx={{ alignItems: "center", justifyContent: "center" }}>
                                <IconButton href={`/#/reminders/${record.id}`}>
                                    <EditIcon />
                                </IconButton>
                                <DeleteButtonComponent title="Delete" resource="reminders" id={record.id} />
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
                <Grid item md={12}>
                    {data?.length == 0 &&
                        <Box sx={{ textAlign: "center", p: 20 }}>
                            <HourglassEmptyIcon sx={{ color: "gray", fontSize: 85 }} />
                            <Typography color="gray" fontSize={18}>You don't have any reminder.</Typography>
                            <Button variant="contained" href={`/#/reminders/create`} sx={{ mt: 1, fontSize: 12 }}>Add Reminder</Button>
                        </Box>
                    }
                </Grid>
            </Grid>
        </>
    );
};

const GridList = () => {
    const { isLoading } = useListContext();
    return <LoadedGridList />;
}

export default GridList;