import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { Dialog, DialogActions, ListItemIcon, TextField, Pagination, Typography, InputAdornment, Checkbox, DialogContent, Button, Grid, List, ListItem, Stack, ListItemButton, Divider, ListItemText, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDelete, useCreate, useGetList, useGetOne, useDataProvider, useTheme } from 'react-admin';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';

interface Props {
    title: string;
    resource: string;
    source: string;
    dataList: any;
    createMode?: boolean;
    createResource?: string;
    createSource?: string;
    onEditItemSelected?: any;
}

const SelectableFilterWithValue: FC<Props> = ({ title, resource, source, dataList, createMode = false, createResource, createSource, onEditItemSelected }) => {

    const [create, { isLoading: loadingCreate, error: errorCreate, data: dataCreate }] = useCreate();
    const [deleteOne, { isLoading: isLoadingDelete, error: errorDelete, data: dataDelete }] = useDelete();

    const [currentPage, setCurrentPage] = React.useState(1);
    const [q, setQ] = React.useState("");

    const { data, total, isLoading, error, refetch } = useGetList(
        resource,
        {
            pagination: { page: currentPage, perPage: 8 },
            sort: { field: 'date', order: 'DESC' },
            filter: {
                q: q
            }
        }
    );

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handlePageChange = (event: any, value: any) => {
        setCurrentPage(value);
    }

    const [value, setValue] = React.useState("");
    const [showAddInput, setShowAddInput] = React.useState(false);

    const onChangeAdd = (e: any) => {
        setValue(e.target.value);
    }

    const [selectedItems, setSelectedItems] = React.useState<any>([]);
    const [onDoneSelectedItems, setOnDoneSelectedItems] = React.useState<any>([]);

    const handleToggle = (record: any) => () => {

        const currentIndex = selectedItems.indexOf(record);
        const newChecked = [...selectedItems];

        if (currentIndex === -1) {
            newChecked.push(record);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedItems(newChecked);

    };

    const onChangeInputValue = (e: any, record: any) => {

        let data: any = [];

        for (let index = 0; index < selectedItems.length; index++) {
            const element: any = selectedItems[index];
            if (element.id === record.id) {
                element.value = e.target.value;
            }
            data.push(element);
        }

        setSelectedItems(data);

    }

    const handleDeleteItem = (record: any) => () => {

        const currentIndex = selectedItems.indexOf(record);
        const newChecked = [...selectedItems];

        if (currentIndex != -1) {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedItems(newChecked);
        setOnDoneSelectedItems(newChecked);
        dataList(newChecked);

    };

    const deleteItem = (record: any) => {
        deleteOne(
            resource,
            { id: record.id, previousData: record }
        );
    }

    const onSubmit = () => {

        create(createResource, {
            data: {
                [`${createSource}`]: value
            }
        })

    }

    const handleDone = () => {
        setOnDoneSelectedItems(selectedItems);
        dataList(selectedItems);
        setOpen(false);
    };

    React.useEffect(() => {
        if (dataCreate) {
            refetch();
            setShowAddInput(false);
            setValue("");
        }
    }, [dataCreate])

    React.useEffect(() => {

        if (onEditItemSelected) {
            setOnDoneSelectedItems(onEditItemSelected);
        }

    }, [onEditItemSelected])

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item md={6} xs={15}>
                    <Button
                        onClick={handleClickOpen}
                        startIcon={<AddIcon />}
                        sx={{
                            backgroundColor: "#333",
                            color: "#fff",
                            fontSize: 15,
                            borderRadius: 4,
                            textTransform: "unset",
                            pl: 2,
                            pr: 2
                        }}
                    >
                        {title}
                    </Button>
                </Grid>
            </Grid>
            {onDoneSelectedItems.length > 0 &&
                <>
                    <Box sx={{ m: 2 }}></Box>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={15}>
                            <List sx={{ width: '100%', maxWidth: "100%" }}>
                                {onDoneSelectedItems.map((record: any) => {

                                    const labelId = `checkbox-list-label-${record.id}`;

                                    return (
                                        <Stack direction="row" spacing={2}>
                                            <ListItem
                                                key={record.id}
                                                secondaryAction={
                                                    <ListItemText id={labelId} primary={`${record.value ? record.value : 0} ALL`} />
                                                }
                                                disablePadding
                                            >
                                                <ListItemButton>
                                                    <ListItemText id={labelId} primary={record[`${source}`] ? record[`${source}`].name : record.name} />
                                                </ListItemButton>
                                            </ListItem>
                                            <IconButton onClick={handleDeleteItem(record)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Stack>
                                    );
                                })}
                            </List>
                            <Divider />
                            <List sx={{ width: '100%', maxWidth: "100%" }}>
                                <ListItem
                                    secondaryAction={
                                        <ListItemText id="total-value" primary={selectedItems.reduce((accumulator: any, object: any) => {
                                            if (object.value) {
                                                return accumulator + JSON.parse(object.value);
                                            } else {
                                                return 0
                                            }
                                        }, 0) + " ALL"} />
                                    }
                                    disablePadding
                                >
                                    <ListItemButton>
                                        <ListItemText id="total-label" primary="Total" />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </>
            }
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        width: 500,
                        height: "100%",
                        backgroundColor: "#000",
                        color: "white"
                    }
                }}
                maxWidth="lg"
            >
                <DialogContent>
                    <Box sx={{ mt: 2, ml: 2, mr: 2 }}>
                        <Typography fontSize={18} fontWeight="bold">{title}</Typography>
                    </Box>
                    <Box sx={{ mt: 2, ml: 2, mr: 2 }}>
                        <TextField
                            id="q"
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            size="small"
                            onChange={(e) => setQ(e.target.value)}
                            placeholder="Search ..."
                        />
                    </Box>
                    <List>
                        {data?.map((record: any) => {
                            const labelId = `checkbox-list-label-${record.id}`;
                            return (
                                <ListItem
                                    key={record.id}
                                    secondaryAction={
                                        <>
                                            <Box sx={{ alignSelf: "center", display: "flex" }}>
                                                <Box sx={{ mr: 5 }}>
                                                    {selectedItems.indexOf(record) !== -1 &&
                                                        <TextField size="small" type="number" onChange={(e) => {
                                                            onChangeInputValue(e, record);
                                                        }} value={record.value} sx={{ width: 100, mr: -3 }} id={`value-${record.id}`} label="Value" />
                                                    }
                                                </Box>
                                                {/* <IconButton disabled={record.settings_id == 1} sx={{ backgroundColor: "#333" }}>
                                                    <EditIcon sx={{ color: record.settings_id == 1 ? "#444" : "#fff" }} />
                                                </IconButton> */}
                                                <IconButton disabled={record.settings_id == 1} sx={{ backgroundColor: "#333", ml: 1 }} onClick={() => deleteItem(record)}>
                                                    <DeleteIcon sx={{ color: record.settings_id == 1 ? "#444" : "#f04e55" }} />
                                                </IconButton>
                                            </Box>
                                        </>
                                    }
                                >
                                    <ListItemButton onClick={handleToggle(record)}>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                tabIndex={-1}
                                                checked={selectedItems.indexOf(record) !== -1}
                                                value={record.value}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText sx={{ ml: -3 }} id={labelId} primary={record.name} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        })}
                        <Box>
                            {data?.length === 0 &&
                                <Box sx={{ textAlign: "center", p: 2 }}>
                                    <Typography color="gray">No results found</Typography>
                                </Box>
                            }
                        </Box>
                        {total as number > 10 &&
                            <Box sx={{ m: 2 }}>
                                <Pagination page={currentPage} onChange={handlePageChange} count={Math.ceil(total as any / 10)} variant="outlined" shape="rounded" />
                            </Box>
                        }
                    </List>
                    {createMode &&
                        <Box sx={{ m: 2 }}>
                            {showAddInput ?
                                <Grid container spacing={1}>
                                    <Grid item md={8}>
                                        <TextField
                                            type="text"
                                            onChange={(e) => onChangeAdd(e)}
                                            label="Add New"
                                            fullWidth
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid item md={2} display="flex">
                                        <Button variant="text" onClick={onSubmit}>Add</Button>
                                        <Button variant="text" sx={{ color: "gray" }} onClick={() => setShowAddInput(false)}>Cancel</Button>
                                    </Grid>
                                </Grid>
                                :
                                <Box>
                                    <Button variant="contained" color="primary" sx={{ width: 250, height: 42, borderRadius: 10 }} onClick={() => setShowAddInput(true)}>Add New</Button>
                                </Box>
                            }
                        </Box>
                    }
                    <Divider sx={{ m: 2, mt: 3 }} />
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Box>
                            <Button variant="contained" color="primary" sx={{ width: 200, height: 42, borderRadius: 10, margin: 1 }} onClick={handleClose}>Cancel</Button>
                        </Box>
                        <Box>
                            <Button variant="contained" color="primary" sx={{ width: 200, height: 42, borderRadius: 10, margin: 1, backgroundColor: "#222" }} onClick={handleDone}>Done</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );

};

export default SelectableFilterWithValue;