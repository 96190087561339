import React, { FC, useState, useEffect, useCallback } from 'react';
import { useNotify, useRefresh, useCreate, useGetOne } from 'react-admin';
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { useMediaQuery, Theme, CircularProgress } from '@mui/material';
import jwt_decode from "jwt-decode";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Checkbox from '@mui/material/Checkbox';

const Create: FC = () => {

    const refresh = useRefresh();
    const navigate = useNavigate();
    const notify = useNotify();

    const [create, { isLoading, error, data }] = useCreate();

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const [checkedOtherOptions, setCheckedOtherOptions] = React.useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = async (data: any) => {
        create('expenses-type', { data: data });
    };

    if (data) {
        notify("Type of expense added successfully", { type: "success" });
        navigate("/expenses-type");
    }

    if (error) {
        notify("Type of expense was not added successfully", { type: "error" });
    }

    return (
        <Box sx={{ pt: 6 }}>
            <Grid container spacing={2}>
                <Grid item md={6} xs={8}>
                    <Typography sx={{ fontSize: 20, color: "#ddd", mb: 2 }}>Add type of expense</Typography>
                </Grid>
            </Grid>
            <Box sx={{ m: 3 }} />
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={10}>
                        <TextField
                            autoComplete="off"
                            id="name"
                            label="Name *"
                            placeholder="Name"
                            {...register("name", { required: true })}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Box sx={{ m: 5 }}>
                    <Divider />
                </Box>
                <Grid container spacing={2}>
                    <Grid item md={1} xs={3}>
                        <Button
                            variant="text"
                            size="large"
                            onClick={() => navigate("/expenses-type")}
                            fullWidth
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item md={1} xs={4}>
                        <Button
                            variant="contained"
                            type="submit"
                            size="large"
                            fullWidth
                        >
                            {isLoading && (
                                <CircularProgress
                                    sx={{ mr: 1.5 }}
                                    size={25}
                                    thickness={2}
                                />
                            )}
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )

}

export default Create;