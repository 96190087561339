import {
    List,
    SimpleList,
    Datagrid,
    useListContext,
    TopToolbar,
    ChipField,
    Pagination,
    ListBase,
    CreateButton,
    ExportButton
} from 'react-admin';
import { Box, Typography, Button, Grid } from '@mui/material';
import { useMediaQuery, Theme, Chip, Stack, Avatar, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { format, addHours } from 'date-fns';
import AddTaskIcon from '@mui/icons-material/AddTask';

// import components
import ColumnList from './ColumnList';
import CreateButtonComponent from '../../components/buttons/Create';

const ResourceList = () => {

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const ListActions = ({ isSmall }: any) => (
        <Box sx={{ pt: 2.5, pb: 1 }}>
            <CreateButtonComponent title="Add New" goTo="route/create" />
        </Box>
    );

    return (
        <Box sx={{ pt: isSmall ? 3 : 7 }}>
            <ListBase perPage={12} sort={{ field: 'reference', order: 'ASC' }}>
                <Box sx={{ backgroundColor: "#222", borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                    <Box sx={{ p: 1, pl: 3, pr: 3 }}>
                        <Box sx={{ display: "flex" }}>
                            <Box sx={{ alignSelf: "center" }}>
                                <Typography fontSize={20} color="#ddd">Route</Typography>
                            </Box>
                            <Box sx={{ flexGrow: 1 }} />
                            <Box sx={{ mt: -1.5 }}>
                                <ListActions />
                            </Box>
                        </Box>
                    </Box>
                    <ColumnList />
                    <Box sx={{ p: 0.2 }}>
                        <Pagination rowsPerPageOptions={[12, 24, 48, 72]} />
                    </Box>
                </Box>
            </ListBase>
        </Box>
    )

}

export default ResourceList;