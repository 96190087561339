import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery, Theme, Grid, Typography } from '@mui/material';
import { useLogout, usePermissions } from 'react-admin';
import Person2Icon from '@mui/icons-material/Person2';
import { useGetOne } from 'react-admin';

const VehicleTaxiStatus = ({ gpsdata, showtext }: any) => {

    const { data, isLoading, error, refetch } = useGetOne('gps-track/vehicle-status', { id: gpsdata.id });

    const variables = data?.variables.reduce((acc: any, variable: any) => {
        acc[variable.name] = variable.value;
        return acc;
    }, {});

    React.useEffect(() => {

        const intervalIdStatusTaxi = setInterval(() => {
            refetch();
        }, 10000);

        return () => {
            clearInterval(intervalIdStatusTaxi);
        };

    }, [])

    return (
        <Box>
            {variables &&
                <>
                    {variables["TaxiMeter"] && variables["Ignition"] &&
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {showtext &&
                                <Typography sx={{
                                    color: variables["Ignition"] == "True" && variables["TaxiMeter"] == "True" ? "#ff4d4d" :  // e kuqe
                                        variables["Ignition"] == "True" && variables["TaxiMeter"] == "False" ? "#00ff00" : // jeshile
                                            variables["Ignition"] == "False" && variables["TaxiMeter"] == "False" ? "#00ff00" : // jeshile
                                                "#00ff00", fontSize: 10, alignSelf: "center", mr: 0.5
                                }}>
                                    {variables["Ignition"] == "True" && variables["TaxiMeter"] == "True" ? "In move" :
                                        variables["Ignition"] == "True" && variables["TaxiMeter"] == "False" ? "Free" :
                                            variables["Ignition"] == "False" && variables["TaxiMeter"] == "False" ? "Free" :
                                                "Free"
                                    }
                                </Typography>
                            }
                            <Person2Icon sx={{
                                color: variables["Ignition"] == "True" && variables["TaxiMeter"] == "True" ? "#ff4d4d" :  // e kuqe
                                    variables["Ignition"] == "True" && variables["TaxiMeter"] == "False" ? "#00ff00" : // jeshile
                                        variables["Ignition"] == "False" && variables["TaxiMeter"] == "False" ? "#00ff00" : // jeshile
                                            "#00ff00"
                            }} />
                        </Box>
                    }
                </>
            }
        </Box>
    );

};

export default VehicleTaxiStatus;