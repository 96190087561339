import * as React from 'react';
import { Box, Typography, Button, Stack, Avatar } from '@mui/material';
import {
    BrowserRouter as Router,
    Route,
    useLocation,
    useSearchParams
} from "react-router-dom";
import AnalyticsIcon from '@mui/icons-material/Analytics';
import InsightsIcon from '@mui/icons-material/Insights';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import HelpIcon from '@mui/icons-material/Help';
import AssistantIcon from '@mui/icons-material/Assistant';
import PaidIcon from '@mui/icons-material/Paid';
import MinorCrashIcon from '@mui/icons-material/MinorCrash';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const IndividualMenu = () => {

    const location = useLocation();

    const data = [
        {
            id: 1,
            title: "Reports",
            items: [
                {
                    id: 1,
                    title: "Analytics",
                    path: "/",
                    icon: <AnalyticsIcon />
                },
                {
                    id: 2,
                    title: "Reports",
                    path: "/reports",
                    icon: <InsightsIcon />
                }
            ]
        },
        {
            id: 2,
            title: "Managemment",
            items: [
                {
                    id: 1,
                    title: "Refueling",
                    path: "/refueling",
                    icon: <LocalGasStationIcon />
                },
                {
                    id: 2,
                    title: "Expenses",
                    path: "/expenses",
                    icon: <AccountBalanceWalletIcon />
                },
                {
                    id: 3,
                    title: "Services",
                    path: "/service",
                    icon: <MinorCrashIcon />
                },
                {
                    id: 4,
                    title: "Incomes",
                    path: "/incomings",
                    icon: <PaidIcon />
                },
                {
                    id: 5,
                    title: "Reminders",
                    path: "/reminders",
                    icon: <NotificationAddIcon />
                },
            ]
        },
        {
            id: 3,
            title: "Support",
            items: [
                {
                    id: 1,
                    title: "Get Help",
                    path: "/help",
                    icon: <HelpIcon />
                },
                // {
                //     id: 2,
                //     title: "Submit feedback",
                //     path: "/feedback",
                //     icon: <AssistantIcon />
                // }
            ]
        }
    ]

    return (
        <>
            {data.map((item) => (
                <Box key={item.id}>
                    <Typography sx={{ color: "#fff", fontSize: 12, mt: 4 }}>{item.title}</Typography>
                    <Box sx={{ mt: 1.5, ml: -0.7 }}>
                        {item.items.map((record: any) => (
                            <Box key={record.id} sx={{ flexDirection: "column" }}>
                                <Button
                                    startIcon={record.icon}
                                    variant="text"
                                    href={`/#${record.path}`}
                                    sx={{ color: location.pathname == record.path ? "#f04e55" : "#bdb9b9", p: 1, width: 200, borderRadius: 2, justifyContent: "flex-start", textTransform: "capitalize" }}
                                >
                                    {record.title}
                                </Button>
                            </Box>
                        ))}
                    </Box>
                </Box>
            ))}
        </>
    )

}

export default IndividualMenu;