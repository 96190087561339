import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery, Theme, Avatar, Typography, Grid, IconButton, DialogTitle, Dialog, Button, DialogContent } from '@mui/material';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PaidIcon from '@mui/icons-material/Paid';
import MinorCrashIcon from '@mui/icons-material/MinorCrash';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import { useNotify, useRefresh, useGetList, useUpdate, useDataProvider, useTheme } from 'react-admin';

// import components
import NumberFormat from '../../../components/other/NumberFormat';
import Price from '../../../components/other/Price';

interface Props {
    record: any;
}

const DriverRefuelRequests = () => {

    const { data, total, isLoading, error } = useGetList(
        'refueling/requests',
        {
            pagination: { page: 1, perPage: 23 },
            sort: { field: 'date', order: 'DESC' }
        }
    );

    const ConfirmDialog: FC<Props> = ({ record }) => {

        const navigate = useNavigate();
        const notify = useNotify()

        const [open, setOpen] = React.useState(false);
        const [update, { isLoading, error, data }] = useUpdate();

        const handleOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
        };

        const confirmRequest = () => {
            update(`refueling/update/status`, { data: { status: "active" }, id: record.id });
        };

        if (data) {
            notify("Refueling request updated successfully", { type: "success" });
            navigate("/refueling");
        }

        if (error) {
            notify("Refueling request was not updated successfully", { type: "error" });
        }

        return (
            <>
                <IconButton onClick={handleOpen}>
                    <CheckIcon sx={{ color: "green" }} />
                </IconButton>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                        sx: {
                            width: 400,
                            backgroundColor: "#000"
                        }
                    }}
                >
                    <DialogTitle id="alert-dialog-title">
                        Refueling Request
                    </DialogTitle>
                    <DialogContent>
                        <Typography color={'gray'}>Do you want to confirm it?</Typography>
                        <Box sx={{ padding: 1, display: "flex", justifyContent: "space-between" }}>
                            <Button onClick={handleClose}>No</Button>
                            <Button onClick={confirmRequest}>Yes</Button>
                        </Box>
                    </DialogContent>
                </Dialog>
            </>
        )
    }

    const CancelDialog: FC<Props> = ({ record }) => {

        const navigate = useNavigate();
        const notify = useNotify();

        const [open, setOpen] = React.useState(false);
        const [update, { isLoading, error, data }] = useUpdate();

        const handleOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
        };

        const cancellRequest = () => {
            update(`refueling/update/status`, { data: { status: "cancelled" }, id: record.id });
        };

        if (data) {
            notify("Refueling request updated successfully", { type: "success" });
            navigate("/refueling");
        }

        if (error) {
            notify("Refueling request was not updated successfully", { type: "error" });
        }

        return (
            <>
                <IconButton onClick={handleOpen}>
                    <CloseIcon sx={{ color: "red" }} />
                </IconButton>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                        sx: {
                            width: 400,
                            backgroundColor: "#000"
                        }
                    }}
                >
                    <DialogTitle id="alert-dialog-title">
                        Refueling Request
                    </DialogTitle>
                    <DialogContent>
                        <Typography color={'gray'}>Do you want to cancelled it?</Typography>
                        <Box sx={{ padding: 1, display: "flex", justifyContent: "space-between" }}>
                            <Button onClick={handleClose}>No</Button>
                            <Button onClick={cancellRequest}>Yes</Button>
                        </Box>
                    </DialogContent>
                </Dialog>
            </>
        )
    }

    return (
        <Box sx={{ backgroundColor: "#222", borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
            <Box sx={{ p: 3 }}>
                <Typography fontSize={20} color="#ddd">Refueling Request</Typography>
                <Typography fontSize={11} color="gray" sx={{ mt: -0.3 }}>Latest active request for refueling</Typography>
            </Box>
            <Grid container spacing={0.2}>
                {data && data?.map((record) => (
                    <Grid item md={12}>
                        <Box sx={{ backgroundColor: "#333", p: 1.5, display: "flex" }}>
                            <Box sx={{ alignSelf: "center" }}>
                                <Avatar />
                            </Box>
                            <Box sx={{ ml: 1.5 }}>
                                <Typography>{record.user.firstname + " " + record.user.lastname}</Typography>
                                <Typography fontSize={11} color="gray" sx={{ mt: -0.3 }}>New request for refuel</Typography>
                                <Typography fontSize={11} color="#ddd">80 L (Litres)</Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "flex-end", flexGrow: 1 }}>
                                <Box>
                                    <CancelDialog record={record} />
                                </Box>
                                <Box>
                                    <ConfirmDialog record={record} />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                ))}
                {data?.length === 0 &&
                    <Box sx={{ backgroundColor: "#333", p: 2, textAlign: "center", width: "100%", height: 300 }}>
                        <Typography mt={4} fontSize={12} color="gray">You don't have any request</Typography>
                    </Box>
                }
            </Grid>
            <Box sx={{ p: 0.2, backgroundColor: "#FFA000" }} />
        </Box>
    );

};

export default DriverRefuelRequests;