import * as React from 'react';
import { Divider, Stack } from '@mui/material';
import { Box, Typography, Avatar, Button, useMediaQuery, Theme } from '@mui/material';
import { useCreatePath, useGetOne, useListContext } from 'react-admin';
import ContentLoader from 'react-content-loader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

const LoadingColumnList = () => {
    return (
        <ContentLoader
            width="100%"
            height={200}
            backgroundColor="#222"
            foregroundColor="#333"
            style={{ padding: 20 }}
        >
            <rect x="0" y="6" rx="4" ry="4" width="100%" height="38" />
            <rect x="0" y="55" rx="4" ry="4" width="100%" height="38" />
            <rect x="0" y="104" rx="4" ry="4" width="100%" height="38" />
        </ContentLoader>
    );
};

const LoadedColumnList = () => {

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const { data } = useListContext();
    if (!data) return null;

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ p: 1, pl: 1.5, fontSize: 12, color: "gray" }}>Name</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((record) => (
                        <TableRow sx={{ backgroundColor: "#333" }} key={record.id}>
                            <TableCell>
                                <Typography fontSize={15}>{record.plan ? record.plan.name : record.addon.name}</Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {data?.length == 0 &&
                <Box sx={{ textAlign: "center", p: isSmall ? 10 : 20 }}>
                    <HourglassEmptyIcon sx={{ color: "gray", fontSize: isSmall ? 50 : 85 }} />
                    <Typography color="gray" fontSize={isSmall ? 15 : 18}>You don't have any subscription.</Typography>
                    <Button variant="contained" href={`/#/subscriptions/create`} sx={{ mt: 1, fontSize: 12 }}>Add subscription</Button>
                </Box>
            }
        </TableContainer>
    );
};

const ColumnList = () => {
    const { isLoading } = useListContext();
    return isLoading ? <LoadingColumnList /> : <LoadedColumnList />;
};

export default ColumnList;