import React, { useRef, FC, useCallback } from "react";
import { Box, Typography, useMediaQuery, Theme, Card, CardContent } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import { useGetList } from "react-admin";

const Upgrade = () => {

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const { data, total, isLoading, error, refetch } = useGetList(
        'plans',
        {
            pagination: { page: 1, perPage: 10 },
            sort: { field: 'date', order: 'DESC' }
        }
    );

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <Button sx={{ backgroundColor: "#333" }} startIcon={<AddIcon />} variant="contained" onClick={handleClickOpen}>
                Add New
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        width: 700,
                        backgroundColor: "#000",
                        color: "white",
                        justifyContent: "center",
                        padding: isSmall ? 20 : 50,
                        textAlign: "center"
                    }
                }}
                maxWidth="lg"
            >
                <Typography fontSize={isSmall ? 20 : 38} fontWeight="bold">Welcome to Pro</Typography>
                <Typography fontSize={17} color="gray">Welcome to Pro</Typography>
                <Box sx={{ display: "flex", mt: 4 }}>
                    {data?.map((record) => (
                        <Card sx={{ width: "100%", m: 1, p: 1.5 }}>
                            <CardContent>
                                <Typography sx={{ fontSize: 25, fontWeight: "bold" }}>
                                    {record.name}
                                </Typography>
                                <Typography sx={{ fontSize: 12, color: "#ddd" }}>{record.description}</Typography>
                                <Box sx={{ mt: 1 }}>
                                    <Typography color="gray">10 Vehicles</Typography>
                                    <Typography color="gray">Download Analytics</Typography>
                                    <Typography color="gray">20 Reminders</Typography>
                                    <Typography color="gray">3 Drivers</Typography>
                                </Box>
                                <Button sx={{ bgcolor: "#222", color: "#fff", p: 1, width: 120, height: 42, fontSize: 12, mt: 2, borderRadius: 15 }}>Contact Us</Button>
                            </CardContent>
                        </Card>
                    ))}
                </Box>
            </Dialog>
        </Box>
    );

};

export default Upgrade;