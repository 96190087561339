import { useState } from 'react';
import {
    Box,
    Grid,
    Button,
    CircularProgress,
    TextField,
    Typography,
    IconButton,
} from '@mui/material';
import { useDataProvider, useLogin, useNotify } from 'react-admin';
import {
    Link
} from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useMediaQuery, Theme } from '@mui/material';
import packageJson from '../../package.json';
import { GoogleLogin } from '@react-oauth/google';

// import images
import logo from "../assets/images/login_logo.png";
import phone1 from "../assets/images/iphone-1.png";
import phone2 from "../assets/images/iphone-4.png";
import phone3 from "../assets/images/iphone-5.png";
import Signup from './Signup';
import jwtDecode from 'jwt-decode';
import { BASE_URL } from '../config';

interface FormValues {
    email?: string;
    password?: string;
}

const Login = () => {

    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const login = useLogin();
    const location = useLocation();

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const [continueRegistration, setContinueRegistration] = useState(false);
    const [googleAccountCredentials, setGoogleAccountCredentials] = useState<any>({});

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (auth: FormValues) => {

        setLoading(true);

        let authLogin = {
            api: '/auth/user/login',
            data: auth
        }

        login(authLogin, location.state ? (location.state as any).nextPathname : '/').catch(
            (error: Error) => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                            ? 'ra.auth.sign_in_error'
                            : error.message,
                    {
                        type: 'warning',
                        messageArgs: {
                            _:
                                typeof error === 'string'
                                    ? error
                                    : error && error.message
                                        ? error.message
                                        : undefined,
                        },
                    }
                );
            }
        );

    };

    return (
        <Box sx={{ backgroundColor: "#111", height: "100vh" }}>
            <Box sx={{ p: 5 }} component="form" onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ textAlign: "center", pt: 5 }}>
                    <img src={logo} height={70} />
                </Box>
                <Box sx={{ textAlign: "center", pt: 4 }}>
                    <Box sx={{ m: 2 }}>
                        <TextField
                            autoComplete="off"
                            id="email"
                            label="Email"
                            size="medium"
                            variant="outlined"
                            inputProps={{
                                style: {
                                    width: isSmall ? 250 : 350,
                                    backgroundColor: "#222",
                                    borderRadius: 10,
                                    color: "#ddd"
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: "gray"
                                }
                            }}
                            type="email"
                            disabled={loading}
                            {...register("email", { required: true })}
                        />
                    </Box>
                    <Box sx={{ m: 2 }}>
                        <TextField
                            autoComplete="off"
                            id="password"
                            label="Password"
                            size="medium"
                            type="password"
                            variant="outlined"
                            inputProps={{
                                style: {
                                    width: isSmall ? 250 : 350,
                                    backgroundColor: "#222",
                                    borderRadius: 10,
                                    color: "#ddd"
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: "gray"
                                }
                            }}
                            {...register("password", { required: true })}
                            disabled={loading}
                        />
                    </Box>
                    <Box sx={{ p: 1 }} />
                    <Box>
                        <Grid container justifyContent="center">
                            {loading ?
                                <CircularProgress
                                    size={50}
                                    thickness={5}
                                />
                                :
                                <Button
                                    variant="contained"
                                    type="submit"
                                    size="large"
                                    color="inherit"
                                    sx={{ backgroundColor: "#f04e55", width: "20vh" }}
                                    disabled={loading}
                                >
                                    Log In
                                </Button>
                            }
                        </Grid>
                    </Box>
                    <Box sx={{ m: 5 }}>
                        <Typography fontSize={14} color="gray">Don't have an account ?</Typography>
                        <Signup continueRegistration={continueRegistration} googleAccountCredentials={googleAccountCredentials} />
                    </Box>
                    <Box sx={{ mb: 3, mt: -2 }}>
                        <Typography color="gray">or</Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <Box sx={{ width: 200 }}>
                            <GoogleLogin
                                width={200}
                                onSuccess={(credentialResponse: any) => {

                                    let decode: any = jwtDecode(credentialResponse.credential);

                                    setLoading(true);

                                    let authLogin = {
                                        api: '/auth/user/google',
                                        data: decode
                                    }

                                    fetch(`${BASE_URL}/auth/user/check-account`, {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            email: decode.email
                                        })
                                    }).then((response) => response.json()).then(async (res) => {

                                        if (res.user == "not_registered") {

                                            setContinueRegistration(true);
                                            setGoogleAccountCredentials(decode);

                                        } else {

                                            login(authLogin, location.state ? (location.state as any).nextPathname : '/').catch(
                                                (error: Error) => {
                                                    setLoading(false);
                                                    notify(
                                                        typeof error === 'string'
                                                            ? error
                                                            : typeof error === 'undefined' || !error.message
                                                                ? 'ra.auth.sign_in_error'
                                                                : error.message,
                                                        {
                                                            type: 'warning',
                                                            messageArgs: {
                                                                _:
                                                                    typeof error === 'string'
                                                                        ? error
                                                                        : error && error.message
                                                                            ? error.message
                                                                            : undefined,
                                                            },
                                                        }
                                                    );
                                                }
                                            );

                                        }

                                    })

                                }}
                                onError={() => {
                                    console.log('Login Failed');
                                }}
                                useOneTap
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Login;