import { AuthProvider } from 'react-admin';
import jwt_decode from "jwt-decode";
import { BASE_URL } from "../config";

const authProvider: AuthProvider = {
    login: (data: any) => {

        const request = new Request(`${BASE_URL}${data.api}`, {
            method: 'POST',
            body: JSON.stringify(data.data),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });

        return fetch(request).then(response => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return response.json();
        }).then((auth) => {
            let decoded: any = jwt_decode(auth.access_token);
            localStorage.setItem("access_token", auth.access_token);
            localStorage.setItem('permissions', JSON.stringify(decoded.permissions));
            return Promise.resolve();
        }).catch((error) => {
            throw new Error("There has been an error at login")
        });

    },
    checkError: (error) => {
        const status = error.status;

        if (status === 401 || status === 403) {
            localStorage.removeItem("access_token");
            return Promise.reject({ redirectTo: '/login' });
        }

        return Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem("access_token") ? Promise.resolve() : Promise.reject();
    },
    logout: () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('permissions');
        return Promise.resolve();
    },
    getIdentity: () => {
        return Promise.resolve({
            id: 1,
            fullName: "Henri Kula"
        })
    },
    getPermissions: () => {

        const storage: any = localStorage.getItem('permissions');
        const permissions = JSON.parse(storage);

        if (permissions) {
            return Promise.resolve(permissions);
        } else {
            localStorage.removeItem("access_token");
            return Promise.reject({ redirectTo: '/login' });
        }

    }
};

export default authProvider;