import React, { FC, useState, useEffect, useCallback } from 'react';
import { useNotify, useRefresh, useCreate, useGetOne } from 'react-admin';
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { useMediaQuery, Theme, CircularProgress } from '@mui/material';
import jwt_decode from "jwt-decode";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Checkbox from '@mui/material/Checkbox';
import SelectableFilter from '../../components/inputs/SelectableFilter';

const Create: FC = () => {

    const navigate = useNavigate();
    const notify = useNotify();
    const refresh = useRefresh();

    const [create, { isLoading, error, data }] = useCreate();

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const { data: settingsVehicleSelected, isLoading: isLoadingSettingsVehicleSelected, error: errorSettingsVehicleSelected } = useGetOne(
        'settings-vehicle-selected/selected',
        {
            id: ""
        }
    );

    const [checkedOtherOptions, setCheckedOtherOptions] = React.useState(false);

    const [startDateValue, setStartDateValue] = React.useState<Dayjs | null>(
        dayjs(new Date())
    );

    const [endDateValue, setEndDateValue] = React.useState<Dayjs | null>(
        dayjs(new Date())
    );

    const [driver, setDriver] = React.useState<any>(0);

    const selectedDriver = (driver: any) => {
        setDriver(driver.id);
    }

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = async (data: any) => {

        data.vehicle_id = settingsVehicleSelected?.vehicle.id;
        data.user_id = driver;
        data.start_date = startDateValue;
        data.end_date = endDateValue;

        if (driver !== 0) {
            create('tasks', { data: data });
        } else {
            notify("Please select driver to assign this task", { type: "info" });
        }

    };

    if (data) {
        notify("Task added successfully", { type: "success" });
        navigate("/tasks");
        refresh();
    }

    if (error) {
        notify("Task was not added successfully", { type: "error" });
    }

    return (
        <Box sx={{ pt: 6 }}>
            <Grid container spacing={2}>
                <Grid item md={6} xs={8}>
                    <Typography sx={{ fontSize: 20, color: "#ddd" }}>Add Task</Typography>
                </Grid>
            </Grid>
            <Box sx={{ m: 5 }} />
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item md={2} xs={8}>
                        <TextField
                            autoComplete="off"
                            id="name"
                            label="Name *"
                            placeholder="Name"
                            InputLabelProps={{
                                shrink: true
                            }}
                            {...register("name", { required: true })}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Box sx={{ m: 4 }} />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container spacing={2}>
                        <Grid item md={2} xs={6}>
                            <MobileDatePicker
                                label="Start Date *"
                                inputFormat="DD/MM/YYYY"
                                value={startDateValue}
                                onChange={(newValue: any) => {
                                    setStartDateValue(newValue);
                                }}
                                renderInput={(params: any) => <TextField fullWidth {...params} />}
                            />
                        </Grid>
                        <Grid item md={2} xs={6}>
                            <MobileDatePicker
                                label="End Date *"
                                inputFormat="DD/MM/YYYY"
                                value={endDateValue}
                                onChange={(newValue: any) => {
                                    setEndDateValue(newValue);
                                }}
                                renderInput={(params: any) => <TextField fullWidth {...params} />}
                            />
                        </Grid>
                    </Grid>
                </LocalizationProvider>
                <Box sx={{ m: 4 }} />
                <Grid container spacing={2}>
                    <Grid item md={2} xs={10}>
                        <TextField
                            autoComplete="off"
                            id="kilometers"
                            type="number"
                            label="Kilometers *"
                            InputLabelProps={{
                                shrink: true
                            }}
                            placeholder="Kilometers"
                            {...register("kilometers", { required: true })}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Box sx={{ m: 3 }}></Box>
                <Grid container spacing={2}>
                    <Grid item md={4} xs={10}>
                        <SelectableFilter resource="drivers" title="Assign to driver" source="firstname" selected={selectedDriver} />
                    </Grid>
                </Grid>
                <Box sx={{ m: 3 }}></Box>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={20}>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={15}>
                                    <Checkbox
                                        checked={checkedOtherOptions}
                                        edge="start"
                                        sx={{ display: "none" }}
                                    />
                                    <Button
                                        startIcon={checkedOtherOptions ? <RemoveIcon /> : <AddIcon />}
                                        onClick={() => { setCheckedOtherOptions((check: any) => !check) }}
                                        sx={{
                                            color: "gray",
                                            fontSize: 15,
                                            pl: 2,
                                            pr: 2
                                        }}
                                    >
                                        More Options
                                    </Button>
                                </Grid>
                            </Grid>
                            {checkedOtherOptions &&
                                <>
                                    <Grid container spacing={2}>
                                        <Grid item md={5} xs={12}>
                                            <TextField
                                                autoComplete="off"
                                                sx={{ mt: 4 }}
                                                id="reason"
                                                label="Reason"
                                                placeholder="Reason (Optional)"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                {...register("reason", { required: false })}
                                                variant="outlined"
                                                multiline
                                                rows={2}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box sx={{ m: 4 }} />
                                    <Grid container spacing={2}>
                                        <Grid item md={5} xs={12}>
                                            <TextField
                                                autoComplete="off"
                                                id="notes"
                                                label="Notes"
                                                placeholder="Notes (Optional)"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                {...register("notes", { required: false })}
                                                variant="outlined"
                                                multiline
                                                rows={4}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ m: 4 }}>
                    <Divider />
                </Box>
                <Grid container spacing={2}>
                    <Grid item md={1} xs={3}>
                        <Button
                            variant="text"
                            size="large"
                            onClick={() => navigate("/tasks")}
                            fullWidth
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item md={1} xs={4}>
                        <Button
                            variant="contained"
                            type="submit"
                            size="large"
                            fullWidth
                        >
                            {isLoading && (
                                <CircularProgress
                                    sx={{ mr: 1.5 }}
                                    size={25}
                                    thickness={2}
                                />
                            )}
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    )

}

export default Create;