import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery, Theme, Grid, Typography } from '@mui/material';
import { useLogout, usePermissions } from 'react-admin';
import { useGetOne } from 'react-admin';

const VehicleStatus = ({ gpsdata }: any) => {

    const { data, isLoading, error, refetch } = useGetOne('gps-track/vehicle-status', { id: gpsdata.id });

    const variables = data?.variables.reduce((acc: any, variable: any) => {
        acc[variable.name] = variable.value;
        return acc;
    }, {});

    React.useEffect(() => {

        const intervalIdStatus = setInterval(() => {
            refetch();
        }, 10000);

        return () => {
            clearInterval(intervalIdStatus);
        };

    }, [])

    return (
        <Box>
            {variables &&
                <>
                    {variables["Ignition"] &&
                        <Box sx={{ width: 15, height: 15, backgroundColor: variables["Ignition"] == "True" ? "#00ff00" : "gray", borderRadius: 0.5 }}>
                            {variables["Ignition"] == "True" ?
                                <Typography></Typography>
                                :
                                <Typography fontSize={10} color="#222" textAlign="center" fontWeight="bold">P</Typography>
                            }
                        </Box>
                    }
                </>
            }
        </Box>
    );

};

export default VehicleStatus;