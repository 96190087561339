import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { ListItemButton, List, ListItemText, DialogTitle, Typography, Dialog, Button, DialogContent } from '@mui/material';

interface Props {
    changeFilter: any;
}

const Filter: FC<Props> = ({ changeFilter }) => {

    const filters = [
        {
            id: 1,
            title: "Last month",
            value: "last_month"
        },
        {
            id: 2,
            title: "Last 3 months",
            value: "last_three_months"
        },
        {
            id: 3,
            title: "Last 6 months",
            value: "last_six_months"
        },
        {
            id: 4,
            title: "Last year",
            value: "last_year"
        },
        {
            id: 5,
            title: "All time",
            value: "all_time"
        }
    ]

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [selectedTitle, setSelectedTitle] = React.useState("Last month");
    const [selectedFilter, setSelectedFilter] = React.useState("last_month");

    const handleListItemClick = (
        type: string,
        title: string,
        index: number,
    ) => {
        setSelectedFilter(type);
        setSelectedTitle(title);
        changeFilter(type);
        handleClose();
    };

    return (
        <Box>
            <Button onClick={handleClickOpen} variant="contained">{selectedTitle}</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                        width: 400,
                        backgroundColor: "#000"
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    Filter by
                </DialogTitle>
                <DialogContent>
                    <List component="nav" aria-label="main mailbox folders">
                        {filters.map((item, index) => (
                            <ListItemButton
                                selected={selectedFilter === item.value}
                                onClick={(event) => handleListItemClick(item.value, item.title, index)}
                            >
                                <ListItemText primary={item.title} />
                            </ListItemButton>
                        ))}
                    </List>
                </DialogContent>
            </Dialog>
        </Box>
    );

};

export default Filter;