import { fetchUtils, DataProvider } from 'react-admin';
import { stringify } from 'query-string';

export default (
    api: string,
    httpClient = fetchUtils.fetchJson
): DataProvider => ({
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const query = {
            sort: field,
            order: order,
            start: page,
            end: perPage,
            filter: JSON.stringify(params.filter),
        };

        const url = `${api}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => {
            if (!headers.has("x-total-count")) {
                throw new Error(
                    "The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?"
                );
            }
            return {
                data: json,
                total: parseInt(
                    //@ts-ignore
                    headers
                        .get("x-total-count")
                        .split("/")
                        .pop(),
                    10
                )
            };
        });
    },
    getOne: (resource, params) =>
        httpClient(`${api}/${resource}/${params.id}`).then(({ json }) => ({
            data: json,
        })),
    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${api}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json }));
    },
    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${api}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => {
            if (!headers.has("x-total-count")) {
                throw new Error(
                    "The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?"
                );
            }
            return {
                data: json,
                total: parseInt(
                    //@ts-ignore
                    headers
                        .get("x-total-count")
                        .split("/")
                        .pop(),
                    10
                )
            };
        });
    },
    update: (resource: any, params: any) => {
        return httpClient(`${api}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => {
            return { data: json }
        })
    },
    updateMany: (resource: any, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${api}/${resource}/${id}`, {
                    method: "PUT",
                    body: JSON.stringify(params.data)
                })
            )
        ).then(responses => ({ data: responses.map(({ json }) => json.id) })),
    create: (resource: any, params: any) => {
        return httpClient(`${api}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        }));
    },
    delete: (resource, params) =>
        httpClient(`${api}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),
    deleteMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${api}/${resource}/${id}`, {
                    method: "DELETE"
                })
            )
        ).then(responses => ({ data: responses.map(({ json }) => json.id) }))
})