import * as React from 'react';
import { Divider, Stack } from '@mui/material';
import { Box, Typography, Theme, useMediaQuery, Button } from '@mui/material';
import { useCreatePath, useGetOne, useListContext } from 'react-admin';
import ContentLoader from 'react-content-loader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';
import { format, utcToZonedTime } from 'date-fns-tz';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import NumberFormat from '../../components/other/NumberFormat';
import EditButtonComponent from '../../components/buttons/Edit';
import DeleteButtonComponent from '../../components/buttons/Delete';

const LoadingColumnList = () => {
    return (
        <ContentLoader
            width="100%"
            height={200}
            backgroundColor="#222"
            foregroundColor="#333"
            style={{ padding: 20 }}
        >
            <rect x="0" y="6" rx="4" ry="4" width="100%" height="38" />
            <rect x="0" y="55" rx="4" ry="4" width="100%" height="38" />
            <rect x="0" y="104" rx="4" ry="4" width="100%" height="38" />
        </ContentLoader>
    );
};

const LoadedColumnList = () => {

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const { data } = useListContext();
    if (!data) return null;

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ p: 1, pl: 1.5, fontSize: 12, color: "gray" }}>Vehicle Model</TableCell>
                        {!isSmall &&
                            <>
                                <TableCell sx={{ p: 1, pl: 2, fontSize: 12, color: "gray" }}>Odometer</TableCell>
                                <TableCell sx={{ p: 1, pl: 2, fontSize: 12, color: "gray" }}>Date & Time</TableCell>
                                <TableCell sx={{ p: 1, pl: 2, fontSize: 12, color: "gray" }}>Tanks</TableCell>
                                <TableCell sx={{ p: 1, pl: 2, fontSize: 12, color: "gray" }}>Status</TableCell>
                            </>
                        }
                        <TableCell sx={{ p: 1, pr: 3, fontSize: 12, color: "gray" }} align="right">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((record) => (
                        <TableRow sx={{ backgroundColor: "#333" }} key={record.id}>
                            <TableCell>
                                <Box sx={{ display: "flex" }}>
                                    <Box sx={{ mr: 3, mt: 1, alignSelf: "center" }}>
                                        <img width={35} src={record.vehicle.make.logo} />
                                    </Box>
                                    <Box sx={{ display: "unset", alignSelf: "center" }}>
                                        <Typography fontSize={15}>{record.vehicle.make.name} {record.vehicle.make_model}</Typography>
                                    </Box>
                                </Box>
                            </TableCell>
                            {!isSmall &&
                                <>
                                    <TableCell><NumberFormat value={record.odometer} colors="#fff" size={15} /></TableCell>
                                    <TableCell>{format(new Date(record.date), 'dd, MMM yyyy')} {format(utcToZonedTime(new Date(record.time), 'Europe/Athens'), 'HH:mm', { timeZone: 'Europe/Athens' })}</TableCell>
                                    <TableCell>
                                        {record.refueling_tanks &&
                                            <>
                                                <Stack divider={<Divider orientation="vertical" sx={{ height: 35, alignSelf: "center" }} flexItem />} direction="row" spacing={2}>
                                                    {record.refueling_tanks.map((item: any) => (
                                                        <Box sx={{ p: 1 }}>
                                                            <Typography sx={{ fontSize: 10, color: "gray" }}>{item.fuel.name}</Typography>
                                                            <Typography sx={{ color: "#ccc" }}>{item.unit_value + " L"}</Typography>
                                                            <Typography sx={{ fontSize: 10, color: "green" }}>{item.type}</Typography>
                                                        </Box>
                                                    ))}
                                                </Stack>
                                            </>
                                        }
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={{ color: record.status == "active" ? "green" : record.status == "cancelled" ? "red" : "yellow", textTransform: "uppercase", fontSize: 14 }}>{record.status}</Typography>
                                    </TableCell>
                                </>
                            }
                            <TableCell align="right">
                                {/* <EditButtonComponent title="Edit" goTo={`/#/refueling/${record.id}`} /> */}
                                <DeleteButtonComponent title="Delete" resource="refueling" id={record.id} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {data?.length == 0 &&
                <Box sx={{ textAlign: "center", p: isSmall ? 10 : 20 }}>
                    <HourglassEmptyIcon sx={{ color: "gray", fontSize: isSmall ? 50 : 85 }} />
                    <Typography color="gray" fontSize={isSmall ? 15 : 18}>You don't have any refuel.</Typography>
                    <Button variant="contained" href={`/#/refueling/create`} sx={{ mt: 1, fontSize: 12 }}>Add Refuel</Button>
                </Box>
            }
        </TableContainer>
    );
};

const ColumnList = () => {
    const { isLoading } = useListContext();
    return isLoading ? <LoadingColumnList /> : <LoadedColumnList />;
};

export default ColumnList;