import React, { FC, useState, useEffect, useCallback } from 'react';
import { useNotify, useRefresh, useCreate, useGetOne } from 'react-admin';
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import { useMediaQuery, Theme, CircularProgress } from '@mui/material';

const Create: FC = () => {

    const navigate = useNavigate();
    const notify = useNotify();
    const refresh = useRefresh();

    const [create, { isLoading, error, data }] = useCreate();

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = async (data: any) => {

        create('vehicle-groups', { data: data });

    };

    if (data) {
        notify("Vehicle group added successfully", { type: "success" });
        navigate("/vehicle-groups");
        refresh();
    }

    if (error) {
        notify("Vehicle group was not added successfully", { type: "error" });
    }

    return (
        <Box sx={{ pt: 6 }}>
            <Grid container spacing={2}>
                <Grid item md={6} xs={8}>
                    <Typography sx={{ fontSize: 20, color: "#ddd", mb: 2 }}>Add Group</Typography>
                </Grid>
            </Grid>
            <Box sx={{ m: 3 }} />
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={10}>
                        <TextField
                            autoComplete="off"
                            id="group_name"
                            label="Group Name *"
                            placeholder="Group Name"
                            {...register("group_name", { required: true })}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Box sx={{ m: 5 }}>
                    <Divider />
                </Box>
                <Grid container spacing={2}>
                    <Grid item md={1} xs={3}>
                        <Button
                            variant="text"
                            size="large"
                            onClick={() => navigate("/vehicle-groups")}
                            fullWidth
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item md={1} xs={4}>
                        <Button
                            variant="contained"
                            type="submit"
                            size="large"
                            fullWidth
                        >
                            {isLoading && (
                                <CircularProgress
                                    sx={{ mr: 1.5 }}
                                    size={25}
                                    thickness={2}
                                />
                            )}
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )

}

export default Create;