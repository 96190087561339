import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery, Theme, Grid, Typography } from '@mui/material';
import { useLogout, usePermissions } from 'react-admin';
import { useGetList } from 'react-admin';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import TextField from '@mui/material/TextField';
import { format } from 'date-fns';
import Address from './Address';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const EventsTab = ({ markerData, gpsdata }: any) => {

    const [startDateValue, setStartDateValue] = React.useState<any>(() => {
        const savedDate = localStorage.getItem('startDate');
        return savedDate ? savedDate : new Date();
    });

    const handleDateChange = (newValue: any) => {
        setStartDateValue(newValue);
        localStorage.setItem('startDate', newValue);
    };

    const eventsTrackUrl = React.useMemo(() => {
        return `gpsgate-provider/events/${format(new Date(`${startDateValue}`), 'yyyy-MM-dd')}/${gpsdata.id}`;
    }, [gpsdata.id, startDateValue]);

    const { data: events, total, isLoading: isLoadingVehicles, error: errorVehicles, refetch } = useGetList(eventsTrackUrl, {
        pagination: { page: 1, perPage: 23 },
        sort: { field: 'date', order: 'DESC' },
        filter: {}
    });

    console.log(events);


    const handleClickEvent = (item: any) => {
        console.log(item);
    }

    return (
        <Box sx={{ mt: 2 }}>
            <Typography color="gray" sx={{ ml: 2 }}>
                Events ({markerData.make.name + " " + markerData.make_model + " - " + markerData.license_plate})
            </Typography>
            <Box sx={{ p: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                        label="Date *"
                        inputFormat="YYYY-MM-DD"
                        value={startDateValue}
                        onChange={handleDateChange}
                        closeOnSelect={true}
                        renderInput={(params: any) => <TextField fullWidth {...params} />}
                    />
                </LocalizationProvider>
            </Box>
            <nav aria-label="main mailbox folders">
                <List>
                    {events?.map((item, index) => (
                        <ListItem disablePadding onClick={() => handleClickEvent(item)} key={index} sx={{ mb: 0.1 }}>
                            <ListItemButton sx={{ backgroundColor: "#333", height: 50 }}>
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Typography color="gray" fontSize={14}>{item.ruleName}</Typography>
                                        {/* <Address title="Start" latitude={item.startPosition.latitude} longitude={item.startPosition.longitude} />
                                        <Address title="End" latitude={item.endPosition.latitude} longitude={item.endPosition.longitude} /> */}
                                    </Box>
                                    <Box>
                                        <ArrowRightIcon sx={{ color: "gray" }} />
                                    </Box>
                                </Box>
                            </ListItemButton>
                        </ListItem>
                    ))}
                    {events?.length === 0 &&
                        <Typography sx={{ textAlign: "center", color: "gray" }}>No events found</Typography>
                    }
                </List>
            </nav>
        </Box>
    );

};

export default React.memo(EventsTab);