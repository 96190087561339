import * as React from 'react';
import { useNotify, useRefresh, useGetList, useGetOne, useDataProvider, useTheme } from 'react-admin';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Box from '@mui/material/Box';
import { Map, AdvancedMarker, Marker, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Person2Icon from '@mui/icons-material/Person2';
import VehicleStatus from './VehicleStatus';
import VehicleTaxiStatus from './VehicleTaxiStatus';
import OverviewTab from './OverviewTab';
import { format, addHours } from 'date-fns';
import Slider from '@mui/material/Slider';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

// import components
import Logo from '../../components/Logo';
import TripsTab from './TripsTab';
import { BASE_URL } from '../../config';
import Polylines from './Polylines';
import EventsTab from './EventsTab';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const GpsTrackFullDialog = () => {

    const map: any = useMap();
    const maps: any = useMapsLibrary("maps");

    const [markerData, setMarkerData] = React.useState<any>({});

    const [loading, setLoading] = React.useState<boolean>(false);
    const [activePolylines, setActivePolylines] = React.useState<boolean>(false);

    const [polylines, setPolylines] = React.useState<any>([]);

    const [open, setOpen] = React.useState(false);

    const [currentPosition, setCurrentPosition] = React.useState(polylines[0]);
    const [sliderValue, setSliderValue] = React.useState(0);

    React.useEffect(() => {
        const index = Math.floor((sliderValue / 100) * (polylines.length - 1));
        setCurrentPosition(polylines[index]);
    }, [sliderValue, polylines]);

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setSliderValue(newValue as number);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { data: vehicles, total, isLoading: isLoadingVehicles, error: errorVehicles, refetch } = useGetList(
        'gps-track/track-vehicles',
        {
            pagination: { page: 1, perPage: 23 },
            sort: { field: 'date', order: 'DESC' },
            filter: {}
        }
    );

    const handleClickMarker = async (marker: any) => {
        console.log(polylines.map((item: any) => {
            return {
                lat: item.position.latitude,
                lng: item.position.longitude
            }
        }));
        setMarkerData(marker);
    }

    const drawPolylineTrip = (data: any) => {

        setLoading(true);
        setPolylines([]);
        setActivePolylines(true);

        const url = `${BASE_URL}/gps-track/users/${data.userId}/tracks/${format(new Date(data.startTrackPoint.utc), 'yyyy-MM-dd')}?startTime=${format(addHours(new Date(data.startTrackPoint.utc), 0), 'HH:mm:ss')}&endTime=${format(addHours(new Date(data.endTrackPoint.utc), 0), 'HH:mm:ss')}&filtered=true`;

        fetch(url, {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        }).then(data => {

            console.log(data);
            setPolylines(data);

        }).catch(error => {
            console.error('There was a problem with the fetch operation:', error);
        });

    }

    React.useEffect(() => {

        if (polylines.length > 0) {

            const bounds = new google.maps.LatLngBounds();

            const pl = [
                new google.maps.Polyline({
                    path: polylines.map((item: any) => ({
                        lat: item.position.latitude,
                        lng: item.position.longitude
                    })),
                    geodesic: true,
                    strokeColor: "#FF0000",
                    strokeOpacity: 1.0,
                    strokeWeight: 3,
                }),
            ];


            pl.forEach(polyline => {
                polyline.setMap(map);
                const path = polyline.getPath();
                path.forEach(latLng => {
                    bounds.extend(latLng);
                });
            });

            map.fitBounds(bounds);
        }

    }, [polylines, map])

    function CustomTabPanel(props: TabPanelProps) {

        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
            </div>
        );

    }

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    React.useEffect(() => {

        const intervalId = setInterval(() => {
            refetch();
        }, 10000);

        return () => {
            clearInterval(intervalId);
        };

    }, [vehicles])

    React.useEffect(() => {

        const intervalId = setInterval(() => {
            refetch();
        }, 10000);

        return () => {
            clearInterval(intervalId);
        };

    }, [vehicles])

    React.useEffect(() => {

        if (currentPosition) {
            console.log(currentPosition);

        }

    }, [currentPosition])

    return (
        <React.Fragment>
            <Box
                onClick={handleClickOpen}
                sx={{
                    mt: 2.5,
                    backgroundColor: "#222",
                    height: 300,
                    overflow: 'hidden',
                    borderRadius: 5
                }}
            >
                <Map
                    mapId={'bf51a910020fa25a'}
                    style={{ height: 350 }}
                    defaultCenter={{ lat: 41.1444374, lng: 18.7069851 }}
                    defaultZoom={8.5}
                    gestureHandling={'greedy'}
                    disableDefaultUI={true}
                >
                    {vehicles?.map((item, index) => (
                        <AdvancedMarker
                            key={index}
                            // ref={markerRef}
                            onClick={() => handleClickMarker(item)}
                            position={{ lat: item.gpsData.trackPoint.position.latitude, lng: item.gpsData.trackPoint.position.longitude }}
                            title={'AdvancedMarker that opens an Infowindow when clicked.'}
                        >
                            <Box
                                sx={{
                                    backgroundColor: "#333",
                                    width: 150,
                                    height: 40,
                                    borderRadius: 3
                                }}
                            >
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Box sx={{ mr: 1.5, mt: 0.7, ml: 0.7 }}>
                                        <VehicleStatus gpsdata={item.gpsData} />
                                    </Box>
                                    <Box>
                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", pt: 0.5 }}>
                                            <Typography sx={{ textAlign: "center" }} fontSize={12} color="#ddd" fontWeight="bold">
                                                {item.make.name}
                                            </Typography>
                                            <Typography sx={{ textAlign: "center", ml: 0.4 }} fontSize={12} color="#ddd" fontWeight="bold">
                                                {item.make_model}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Typography sx={{ textAlign: "center", mt: -0.4, ml: -0.5 }} fontSize={9} color="#ddd">
                                                {item.license_plate}
                                            </Typography>
                                            <Typography sx={{ mt: -0.4, ml: 0.5 }} fontSize={9} color={Math.round(item.gpsData.calculatedSpeed * 3.6) >= 100 ? "#ef4e55" : Math.round(item.gpsData.calculatedSpeed * 3.6) === 0 ? "gray" : "#bcd96e"}>
                                                {Math.round(item.gpsData.calculatedSpeed * 3.6)} kmh
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ mt: 1, ml: 1 }}>
                                        <VehicleTaxiStatus gpsdata={item.gpsData} showtext={false} />
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ width: 12, height: 12, backgroundColor: "#333", borderRadius: 15, ml: 8.9, mt: 0.1 }} />
                        </AdvancedMarker>
                    ))}
                </Map>
            </Box>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                PaperProps={{
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none"
                    },
                }}
            >
                <AppBar sx={{ position: 'relative', borderRadius: 0 }}>
                    <Toolbar sx={{ backgroundColor: "#222" }}>
                        <Box sx={{ ml: -1, width: 10, mt: 1, mr: 4 }}>
                            <Logo />
                        </Box>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Gps Track
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            sx={{ mr: -2 }}
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Map
                    mapId={'bf51a910020fa25a'}
                    style={{ height: "100vh" }}
                    defaultCenter={{ lat: 41.1444374, lng: 18.7069851 }}
                    defaultZoom={8.5}
                    gestureHandling={'greedy'}
                >
                    {!activePolylines && vehicles?.map((item, index) => (
                        <AdvancedMarker
                            key={index}
                            // ref={markerRef}
                            onClick={() => handleClickMarker(item)}
                            position={{ lat: item.gpsData.trackPoint.position.latitude, lng: item.gpsData.trackPoint.position.longitude }}
                            title={'AdvancedMarker that opens an Infowindow when clicked.'}
                        >
                            <Box
                                sx={{
                                    backgroundColor: "#333",
                                    width: 150,
                                    height: 40,
                                    borderRadius: 3
                                }}
                            >
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Box sx={{ mr: 1.5, mt: 0.7, ml: 0.7 }}>
                                        <VehicleStatus gpsdata={item.gpsData} />
                                    </Box>
                                    <Box>
                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", pt: 0.5 }}>
                                            <Typography sx={{ textAlign: "center" }} fontSize={12} color="#ddd" fontWeight="bold">
                                                {item.make.name}
                                            </Typography>
                                            <Typography sx={{ textAlign: "center", ml: 0.4 }} fontSize={12} color="#ddd" fontWeight="bold">
                                                {item.make_model}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Typography sx={{ textAlign: "center", mt: -0.4, ml: -0.5 }} fontSize={9} color="#ddd">
                                                {item.license_plate}
                                            </Typography>
                                            <Typography sx={{ mt: -0.4, ml: 0.5 }} fontSize={9} color={Math.round(item.gpsData.calculatedSpeed * 3.6) >= 100 ? "#ef4e55" : Math.round(item.gpsData.calculatedSpeed * 3.6) === 0 ? "gray" : "#bcd96e"}>
                                                {Math.round(item.gpsData.calculatedSpeed * 3.6)} kmh
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ mt: 1, ml: 1 }}>
                                        <VehicleTaxiStatus gpsdata={item.gpsData} showtext={false} />
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ width: 12, height: 12, backgroundColor: "#333", borderRadius: 15, ml: 8.9, mt: 0.1 }} />
                        </AdvancedMarker>
                    ))}
                    {activePolylines && polylines.length > 0 &&
                        <>
                            <AdvancedMarker
                                // ref={markerRef}
                                position={{ lat: polylines[0].position.latitude, lng: polylines[0].position.longitude }}
                                title={'AdvancedMarker that opens an Infowindow when clicked.'}
                            >
                                <Box sx={{ height: 20, pl: 2, pr: 2, backgroundColor: "#333", borderRadius: 15 }}>
                                    <Typography fontSize={13}>Start</Typography>
                                </Box>
                            </AdvancedMarker>
                            <AdvancedMarker
                                // ref={markerRef}
                                position={{ lat: polylines[polylines.length - 1].position.latitude, lng: polylines[polylines.length - 1].position.longitude }}
                                title={'AdvancedMarker that opens an Infowindow when clicked.'}
                            >
                                <Box sx={{ height: 20, pl: 2, pr: 2, backgroundColor: "#333", borderRadius: 15 }}>
                                    <Typography fontSize={13}>End</Typography>
                                </Box>
                            </AdvancedMarker>
                            {currentPosition &&
                                <AdvancedMarker
                                    // ref={markerRef}
                                    position={{ lat: currentPosition.position.latitude, lng: currentPosition.position.longitude }}
                                    title={'AdvancedMarker that opens an Infowindow when clicked.'}
                                >
                                    <Box sx={{ height: 30, width: 30, backgroundColor: "#333", borderRadius: 15 }}>
                                        <DirectionsCarIcon sx={{ fontSize: 20, ml: 0.62, mt: 0.35 }} />
                                    </Box>
                                </AdvancedMarker>
                            }
                            <Box sx={{ display: "flex", backgroundColor: "#333", position: "absolute", bottom: 30, borderRadius: 5, right: 80, width: "60%", height: 80 }}>
                                {currentPosition &&
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Box sx={{ mt: 2, width: "80%", ml: 5 }}>
                                            <Slider
                                                aria-label="Polyline Position"
                                                value={sliderValue}
                                                onChange={handleSliderChange}
                                                defaultValue={0}
                                                color="primary"
                                            />
                                            <Box sx={{ display: "flex" }}>
                                                <Typography fontSize={12} sx={{ flexGrow: 1, fontWeight: "bold" }}>Speed: {Math.round(currentPosition.variables.speed * 3.6)} kmh</Typography>
                                                <Typography fontSize={12}>Time: {format(new Date(`${currentPosition.utc}`), 'dd MMM yyyy, HH:mm:ss')}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                }
                                <Box sx={{ mr: 2.5, mt: 3 }}>
                                    <Button onClick={() => {
                                        setActivePolylines(false);
                                        setPolylines([])
                                    }}>
                                        Close
                                    </Button>
                                </Box>
                            </Box>
                        </>
                    }
                </Map>
                <Box
                    sx={{
                        backgroundColor: "#222",
                        height: "100vh",
                        width: 350,
                        position: "absolute",
                        overflowY: "auto",
                        bottom: 0,
                        left: 0
                    }}
                >
                    <Box sx={{ mt: 10 }}>
                        <Typography sx={{ ml: 2.5 }} color="gray">
                            Vehicles
                        </Typography>
                        <nav aria-label="main mailbox folders">
                            <List>
                                {vehicles?.map((item, index) => (
                                    <ListItem disablePadding onClick={() => handleClickMarker(item)} key={index}>
                                        <ListItemButton>
                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                                <Box sx={{ mr: 2 }}>
                                                    <VehicleStatus gpsdata={item.gpsData} />
                                                </Box>
                                                <Box sx={{ flexGrow: 1 }}>
                                                    <Typography color="#ddd">{item.make.name + " " + item.make_model}</Typography>
                                                    <Typography color="gray" fontSize={12}>{item.license_plate}</Typography>
                                                </Box>
                                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                                    <Typography color={Math.round(item.gpsData.calculatedSpeed * 3.6) >= 100 ? "#ef4e55" : Math.round(item.gpsData.calculatedSpeed * 3.6) === 0 ? "gray" : "#bcd96e"} fontSize={12}>{Math.round(item.gpsData.calculatedSpeed * 3.6)} kmh</Typography>
                                                    <VehicleTaxiStatus gpsdata={item.gpsData} showtext={true} />
                                                </Box>
                                            </Box>
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </nav>
                        {Object.keys(markerData).length !== 0 &&
                            <>
                                <Typography sx={{ ml: 2.5, mt: 2 }} color="gray">
                                    Other Details
                                </Typography>
                                <Box sx={{ width: 350, mt: 2 }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                                            <Tab label="Overview" {...a11yProps(0)} />
                                            <Tab label="Trips" {...a11yProps(1)} />
                                            <Tab label="Events" {...a11yProps(2)} />
                                        </Tabs>
                                    </Box>
                                    <CustomTabPanel value={value} index={0}>
                                        <OverviewTab markerData={markerData} gpsdata={markerData.gpsData} />
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={1}>
                                        <TripsTab markerData={markerData} gpsdata={markerData.gpsData} setTripId={drawPolylineTrip} />
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={2}>
                                        <EventsTab markerData={markerData} gpsdata={markerData.gpsData} />
                                    </CustomTabPanel>
                                </Box>
                            </>
                        }
                    </Box>
                </Box>
            </Dialog>
        </React.Fragment>
    );

}

export default GpsTrackFullDialog;