import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery, Theme, Grid, Typography, Stack, Button, Divider } from '@mui/material';
import {
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Brush,
    AreaChart,
    Area,
    ResponsiveContainer,
} from 'recharts';
import { useGetOne } from 'react-admin';

interface Props {
    type: string;
}

const LineChart: FC<Props> = ({ type }) => {

    const { data, isLoading, error } = useGetOne(`analytics/${type}/linechart/bydays`, { id: "" });

    return (
        <>
            {data &&
                <ResponsiveContainer width="100%" height={400}>
                    <AreaChart
                        width={500}
                        height={200}
                        data={data.data}
                        syncId="anyId"
                        margin={{
                            top: 39,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Area type="monotone" dataKey="pv" stroke="#111" fill="#222" />
                    </AreaChart>
                </ResponsiveContainer>
            }
        </>
    );

};

export default LineChart;