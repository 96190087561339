import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery, Theme, Grid, Typography, Stack, Button, Divider } from '@mui/material';
import { useCreate, useNotify } from 'react-admin';
import { useNavigate } from "react-router-dom";

interface Props {
    filter_type: string;
}

const SaveDashboard: FC<Props> = ({ filter_type }) => {

    const navigate = useNavigate();
    const notify = useNotify();

    const [create, { isLoading, error, data }] = useCreate();

    const save = () => {
        create("analytics/save", {
            data: {
                filter_type_title: "",
                filter_type_value: filter_type
            }
        })
    }

    if (data) {
        notify("Dashboard saved successfully", { type: "success" });
        navigate("/");
    }

    if (error) {
        notify("Dashboard was not saved successfully", { type: "error" });
    }

    return (
        <Box>
            <Button onClick={save} variant="contained" sx={{ color: "gray" }}>Save to dashboard</Button>
        </Box>
    )
}

export default SaveDashboard;