import React, { useRef, FC, useCallback } from "react";
import { Box, Button } from '@mui/material';
import {
    List,
    SimpleList,
    Datagrid,
    useListContext,
    TopToolbar,
    ChipField,
    Pagination,
    ListBase,
    CreateButton,
    useGetOne
} from 'react-admin';
import AddIcon from '@mui/icons-material/Add';

// components
import Upgrade from '../other/Upgrade';

interface Props {
    title: string;
    goTo: string;
    type: string;
    source_limit: string;
    source_count: string;
}

const LockCreate: FC<Props> = ({ title, goTo, type, source_count, source_limit }) => {

    const { data, isLoading, error } = useGetOne('subscriptions/check/active', { id: "" });

    return (
        <Box>
            {data?.purchased_limit[`${type}`][`${source_limit}`] > data?.purchased_limit[`${type}`][`${source_count}`] ?
                <Button sx={{ backgroundColor: "#333" }} startIcon={<AddIcon />} variant="contained" href={`/#/${goTo}`}>{title}</Button>
                :
                <Upgrade />
            }
        </Box>
    );

};

export default LockCreate;